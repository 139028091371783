<div>
    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="settings.notifications"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.page_title_view_notifications' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <ng-container *ngIf="groups && services">
                        <div class="mb-3" *ngFor="let group of groups">
                            <div class="notification-heading-row mb-2 py-2">
                                <div class="row">
                                    <div class="col-6">
                                        <strong>{{ 'notifications.groups.' + group | translate }}</strong>
                                    </div>
                                    <div class="col-6 d-flex justify-content-around">
                                        <ng-container *ngFor="let service of services">
                                            <div class="flex-grow-1 text-center">{{ 'notifications.services.' + service | translate }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <div class="row notification-row mt-1" *ngFor="let groupNotificationName of getGroupNotifications(group)">
                                <div class="col-6 py-1">
                                    {{ 'notifications.' + groupNotificationName + '_title' | translate }}
                                </div>
                                <div class="col-6 d-flex justify-content-around">
                                    <div *ngFor="let service of services" class="flex-grow-1 d-flex align-items-center justify-content-around">
                                        <form>
                                            <ng-container *ngIf="checkAvailable(group, groupNotificationName, service); else ToggleDisabled">
                                                <input data-switch="success" type="checkbox"
                                                       id="notification-{{groupNotificationName}}-{{service}}"
                                                       name="notification-{{groupNotificationName}}-{{service}}"
                                                       [checked]="checkChecked(group, groupNotificationName, service)"
                                                       (change)="updateNotification($event, group, groupNotificationName, service)"
                                                >
                                                <label for="notification-{{groupNotificationName}}-{{service}}" class="mb-0">&nbsp;</label>
                                            </ng-container>

                                            <ng-template #ToggleDisabled>
                                                <input data-switch="success" type="checkbox"
                                                       id="notification-{{groupNotificationName}}-{{service}}"
                                                       name="notification-{{groupNotificationName}}-{{service}}"
                                                       [checked]="checkChecked(group, groupNotificationName, service)"
                                                       disabled readonly
                                                >
                                                <label for="notification-{{groupNotificationName}}-{{service}}" class="mb-0"
                                                       [style.opacity]="0.3"
                                                       placement="top"
                                                       ngbTooltip="{{checkDisabled(group, groupNotificationName, service) ? ('notifications.setting_is_disabled' | translate) : !checkAvailable(group, groupNotificationName, service) ? ('notifications.setting_not_available' | translate) : '' }}"
                                                >&nbsp;</label>
                                            </ng-template>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="settings.weekly_report_setting"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.weekly_report_setting_title' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <form (ngSubmit)="saveWeeklyReportRecipients()">
                        <div class="row mb-2">
                            <div class="col-6">
                                <label class="control-label mr-2" for="weekly_report_to_admin">{{ 'settings.weekly_report_to_admins' | translate }}</label>
                            </div>
                            <div class="col-6 text-right">
                                <input [(ngModel)]="weeklyReportToAdmin" aria-controls="collapse" data-switch="success" id="weekly_report_to_admin" name="weekly_report_to_admin" type="checkbox">
                                <label for="weekly_report_to_admin">&nbsp;</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="control-label mr-2" for="weekly_report_to_users">{{ 'settings.weekly_report_to_users' | translate }}:</label>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ng-select
                                        [clearable]="true"
                                        [items]="availableEmployeeUsers$ | async"
                                        bindLabel="fullname"
                                        bindValue="user_ID"
                                        class="custom"
                                        name="weekly_report_users"
                                        id="weekly_report_to_users"
                                        [(ngModel)]="weeklyReportUserIds"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label class="control-label mr-2" for="timeframe">{{ 'settings.weekly_report_timeframe' | translate }}:</label>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ng-select
                                        [clearable]="false"
                                        [items]="[7, 14, 21, 28]"
                                        class="custom"
                                        name="timeframe"
                                        id="timeframe"
                                        [(ngModel)]="timeframe"
                                        [multiple]="false"
                                        [closeOnSelect]="true"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="settings.absence_report"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.absence_report_setting_title' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <form (ngSubmit)="saveReportRecipients('absence_report', absenceReportUserIds)">
                        <div class="row">
                            <div class="col-12">
                                <label class="control-label mr-2" for="absence_report_to_users">{{ 'settings.absence_report_to_users' | translate }}:</label>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ng-select
                                        [clearable]="true"
                                        [items]="availableEmployeeUsers$ | async"
                                        bindLabel="fullname"
                                        bindValue="user_ID"
                                        class="custom"
                                        name="absence_report_users"
                                        id="absence_report_to_users"
                                        [(ngModel)]="absenceReportUserIds"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="settings.activity_log_report"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.activity_log_report_setting_title' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <form (ngSubmit)="saveReportRecipients('activity_log_report', activityLogReportUserIds)">
                        <div class="row">
                            <div class="col-12">
                                <label class="control-label mr-2" for="activity_log_report_to_users">{{ 'settings.activity_log_report_to_users' | translate }}:</label>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ng-select
                                        [clearable]="true"
                                        [items]="availableUsers$ | async"
                                        bindLabel="fullname"
                                        bindValue="user_ID"
                                        class="custom"
                                        name="activity_log_report_users"
                                        id="activity_log_report_to_users"
                                        [(ngModel)]="activityLogReportUserIds"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="card shadow">
                <app-help name="settings.questionnaire_report"></app-help>

                <div class="card-header py-3">
                    <div class="row">
                        <div class="col-sm-6 text-left my-auto">
                            <p class="modal-title font-16 text-dark pt-2 pb-2">
                                <span>{{ 'settings.questionnaire_report_setting_title' | translate}}</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <form (ngSubmit)="saveReportRecipients('questionnaire_report', questionnaireReportUserIds)">
                        <div class="row">
                            <div class="col-12">
                                <label class="control-label mr-2" for="questionnaire_report_to_users">{{ 'settings.questionnaire_report_to_users' | translate }}:</label>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <ng-select
                                        [clearable]="true"
                                        [items]="availableUsers$ | async"
                                        bindLabel="fullname"
                                        bindValue="user_ID"
                                        class="custom"
                                        name="questionnaire_report_users"
                                        id="questionnaire_report_to_users"
                                        [(ngModel)]="questionnaireReportUserIds"
                                        [multiple]="true"
                                        [closeOnSelect]="false"
                                    ></ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
