<div class="modal-content" xmlns="http://www.w3.org/1999/html">
  <div class="modal-header">
    <p
      *ngIf="!activity"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_activities.page_title_create' | translate }}</p>

    <p
      *ngIf="activity"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_activities.page_title_edit' | translate }}</p>

    <button
      aria-label="Close"
      class="close"
      (click)="activeModal.dismiss()"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              autocomplete="off"
              [formGroup]="form"
              (ngSubmit)="onSubmit()"
            >
              <div *ngIf="type === 'EMPLOYEE'" class="row">
                <div class="col-6">
                    <label class="control-label">
                      <span>{{ 'employees_activities.visibility_public' | translate }}</span>
                    </label>
                </div>
                <div class="col-6 text-right">
                  <input formControlName="public" type="checkbox" id="public" name="public" data-switch="success">
                  <label for="public">&nbsp;</label>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_activities.date' | translate }}</span>
                    </label>

                    <input
                      mwlFlatpickr
                      allowInput="true"
                      altFormat="d.m.Y"
                      altInput="true"
                      class="form-control"
                      dateFormat="Y-m-d"
                      formControlName="date"
                      name="date"
                      type="text"
                      [locale]="locale$ | async"
                      (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                    >

                    <div *ngIf="submitted && f.date.errors">
                      <p
                        *ngIf="f.date.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_activities.date' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

                <div *ngIf="type === 'EMPLOYEE'" class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="type-select" class="control-label">
                                <span>{{ 'employees_activities.type' | translate }}</span>
                            </label>
                            <ng-select id="type-select" class="custom" formControlName="type" [clearable]="false">
                                <ng-option *ngIf="type === 'EMPLOYEE'" value="EMAIL">
                                    <span>{{ 'employees_activities.types.EMAIL' | translate }}</span>
                                </ng-option>

                                <ng-option *ngIf="type === 'EMPLOYEE'" value="NOTE">
                                    <span>{{ 'employees_activities.types.NOTE' | translate }}</span>
                                </ng-option>

                                <ng-option *ngIf="type === 'EMPLOYEE'" value="OTHER">
                                    <span>{{ 'employees_activities.types.OTHER' | translate }}</span>
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div *ngIf="form.value.type === 'EMAIL' && !activity?.notification_sent_at" class="row">
                    <div class="col-6">
                        <label class="control-label">
                            <span>{{ 'employees_activities.notify' | translate }}</span>
                        </label>
                    </div>
                    <div class="col-6 text-right">
                        <input formControlName="notify" type="checkbox" id="notify" name="notify" data-switch="success">
                        <label for="notify">&nbsp;</label>
                    </div>
                </div>

                <div *ngIf="form.value.type === 'EMAIL' && activity?.notification_sent_at" class="row">
                    <div class="col-12 text-right">
                        <small class="text-muted">{{ 'employees_activities.notification_sent_at' | translate}} {{ activity?.notification_sent_at | date: 'dd.MM.yyyy HH:mm' }} </small>
                    </div>
                </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_activities.name' | translate }}</span>
                    </label>

                    <input
                      class="form-control"
                      formControlName="name"
                      name="name"
                      type="text"
                    >

                    <div *ngIf="submitted && f.name.errors">
                      <p
                        *ngIf="f.name.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_activities.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

                <app-ai-helper *ngIf="!activity" [formGroup]="form" [scope]="'ACTIVITY'" [controlName]="'description'"></app-ai-helper>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_activities.description' | translate }}</span>
                    </label>

                    <textarea
                      class="form-control"
                      formControlName="description"
                      name="description"
                      rows="5"
                      type="textarea"
                    ></textarea>

                    <div *ngIf="submitted && f.description.errors">
                      <p
                        *ngIf="f.description.errors.required"
                        class="text-danger validation-text"
                      >
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees_activities.description' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="activity">
                <app-upload
                  [fileName]="activity.name"
                  [modelName]="'employee_activity_ID'"
                  [modelValue]="activity.employee_activity_ID"
                  [secondaryModelValue]="null"
                  (success)="fetchFiles()"
                ></app-upload>

                <app-files-table
                  *ngIf="files$"
                  [allowDelete]="canEdit$ | async"
                  [files$]="files$"
                  [object]="activity"
                  [objectType]="'EMPLOYEE_ACTIVITY'"
                  (refetch)="fetchFiles()"
                ></app-files-table>
              </ng-container>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <div *ngIf="activity">
                    <button type="button" (click)="deleteActivity()" class="btn btn-link text-muted pr-0 mr-4">
                      <u *ngIf="canEdit$ | async">{{'accounts_categories.action_delete' | translate}}</u>
                    </button>
                  </div>
                  <button type="submit" class="btn btn-success" *ngIf="canEdit$ | async">
                    {{ 'accounts_categories.action_save' | translate }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
