import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/setting/services/company.service';
import { SettingGuideBasicModalComponent } from '../../partials/setting-guide-basic-modal/setting-guide-basic-modal.component';

@Component({
    selector: 'app-company-settings-basic',
    templateUrl: './company-settings-basic.component.html',
    styleUrls: ['./company-settings-basic.component.css']
})
export class CompanySettingsBasicComponent implements OnInit {

    @Input() companySettings$: Observable<any>;

    @Output() refetchCompanySettings = new EventEmitter<string>();

    public companyBasicSettingEditForm: UntypedFormGroup;
    public availableCurrencies = [
        {value: 'czk', label: this._translateService.instant('settings.company.billing.czk')},
        {value: 'eur', label: this._translateService.instant('settings.company.billing.eur')},
        /* USD {value: 'usd', label: this._translateService.instant('settings.company.billing.usd')} USD */
    ];
    public submitted = false;

    public constructor(
        private _companyService: CompanyService,
        private _translateService: TranslateService,
        private _fb: UntypedFormBuilder,
        private _modalService: NgbModal,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.companyBasicSettingEditForm.controls;
    }

    public ngOnInit(): void {
        this.companyBasicSettingEditForm = this._fb.group({
            'company.info.label': [''],
            'company.info.in': [''],
            'company.info.tin': [''],
            'company.info.registered_for_vat': [''],
            'company.info.registration_text': [''],
            'company.info.email': [''],
            'company.info.phone': [''],
            'company.info.description': [''],
        });

        this.loadCompanySettings();
    }

    public loadCompanySettings() {
        this.companySettings$ = this.companySettings$
            .pipe(
                tap(settings => {
                    this.companyBasicSettingEditForm.patchValue({
                        'company.info.label': settings.company_settings.info?.label,
                        'company.info.in': settings.company_settings.info?.in,
                        'company.info.tin': settings.company_settings.info?.tin,
                        'company.info.registered_for_vat': settings.company_settings.info?.registered_for_vat === '1',
                        'company.info.registration_text': settings.company_settings.info?.registration_text,
                        'company.info.email': settings.company_settings.info?.email,
                        'company.info.phone': settings.company_settings.info?.phone,
                        'company.info.description': settings.company_settings.info?.description,
                    });
                })
            );
    }

    public onSubmit(): void {
        this.submitted = true;

        if (!this.companyBasicSettingEditForm.valid) {
            return;
        }

        const value = this.companyBasicSettingEditForm.value;

        value.settings_type = 'info';

        this._companyService.saveCompanySetting(value);
    }

    public showAresModal() {
        const formValues = this.companyBasicSettingEditForm.value;
        const identificationNumber = formValues['company.info.in'];

        const modalRef = this._modalService.open(SettingGuideBasicModalComponent, {centered: true});

        modalRef.componentInstance.in = identificationNumber;

        modalRef.result
            .then(
                () => {
                    this.refetchCompanySettings.emit('refetch');
                },
                () => {}
            );
    }
}
