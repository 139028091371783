<form [formGroup]="form"
      autocomplete="off"
>
    <div class="row">
        <div *ngIf="!isQuestionnaire" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.personal_code' | translate }}</span>
                </label>
                <input (keyup)="inputChanged($event)"
                       class="form-control"
                       formControlName="personal_code"
                       name="personal_code"
                       type="text"
                >

                <div *ngIf="personalCodetaken === true">
                    <p class="text-danger">
                        <span>{{ 'employees.personal_code_taken' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div *ngIf="!isQuestionnaire" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.abbreviation' | translate }}</span>
                </label>
                <input class="form-control"
                       formControlName="abbreviation"
                       name="abbreviation"
                       type="text"
                >
            </div>
        </div>

        <div *ngIf="employee" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.degree' | translate }}</span>
                </label>
                <input class="form-control"
                       formControlName="degree_before"
                       name="degree_before"
                       type="text"
                >
            </div>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.name' | translate }}</span>
                </label>

                <input
                    [class.is-invalid]="submitted && f.name.errors"
                    autocomplete="none"
                    class="form-control"
                    formControlName="name"
                    name="name"
                    type="text"
                >

                <div *ngIf="submitted && f.name.errors">
                    <p *ngIf="f.name.errors.required" class="text-danger">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.name' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.surname' | translate }}</span>
                </label>

                <input
                    [class.is-invalid]="submitted && f.surname.errors"
                    autocomplete="none"
                    class="form-control"
                    formControlName="surname"
                    name="surname"
                    type="text"
                >

                <div *ngIf="submitted && f.surname.errors">
                    <p *ngIf="f.surname.errors.required" class="text-danger">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.surname' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.born_surname' | translate }}</span>
                </label>

                <input
                    [class.is-invalid]="submitted && f.born_surname.errors"
                    autocomplete="none"
                    class="form-control"
                    formControlName="born_surname"
                    name="born_surname"
                    type="text"
                >
            </div>
        </div>

        <div *ngIf="employee" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.degree_2' | translate }}</span>
                </label>
                <input class="form-control"
                       formControlName="degree_after"
                       name="degree_after"
                       type="text"
                >
            </div>
        </div>
    </div>

    <hr *ngIf="employee && !isQuestionnaire">

    <div *ngIf="!isQuestionnaire" class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.email' | translate }}</span>
                </label>

                <input
                    (keyup)="inputChanged($event)"
                    [class.is-invalid]="submitted && f.email.errors"
                    autocomplete="none"
                    class="form-control"
                    formControlName="email"
                    name="email"
                    type="text"
                >
                <div *ngIf="emailTaken === true">
                    <p class="text-danger">
                        <span>{{ 'users.email_taken' | translate }}</span>
                    </p>
                </div>
                <div *ngIf="submitted && f.email.errors">
                    <p *ngIf="f.email.errors.required" class="text-danger">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.email' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>

                    <p *ngIf="f.email.errors.email" class="text-danger">
                        <span>{{ 'angular_validation.valid_email' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>

        <div *ngIf="employee" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.phone' | translate }}</span>
                </label>

                <input
                    [class.is-invalid]="submitted && f.phone.errors"
                    class="form-control"
                    formControlName="phone"
                    name="phone"
                    type="text"
                >

                <div *ngIf="submitted && f.phone.errors">
                    <p *ngIf="f.phone.errors.email" class="text-danger">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.personal_email' | translate }} </span>
                        <span>{{ 'angular_validation.valid_email' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="employee">

    <div class="row">
        <div *ngIf="employee" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.facebook' | translate }}</span>
                </label>
                <input class="form-control"
                       formControlName="facebook"
                       name="facebook"
                       type="text"
                >
            </div>
        </div>

        <div *ngIf="employee" class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.linkedin' | translate }}</span>
                </label>
                <input class="form-control"
                       formControlName="linkedin"
                       name="linkedin"
                       type="text"
                >
            </div>
        </div>
    </div>

    <div *ngIf="!isQuestionnaire && !employee && showRole" class="row">
        <div class="col-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.company_group' | translate }}</span>
                </label>
                <ng-select class="custom"
                           formControlName="company_group_ID"
                           name="company_group_ID"
                           [items]="companyGroups$ | async"
                           bindValue="company_group_ID"
                           bindLabel="name"
                           [closeOnSelect]="true"
                           [clearable]="false"
                           [loading]="companyGroupsLoading"
                           [multiple]="false">
                </ng-select>
                <div *ngIf="submitted && f.role.errors">
                    <p *ngIf="f.role.errors.required" class="text-danger validation-text">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.company_group' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isQuestionnaire && !employee && showRole" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label class="control-label">
                    <span>{{ 'employees.role' | translate }}</span>
                </label>
                <ng-select [items]="employeeRoles$ | async" bindLabel="name" bindValue="role_ID" class="custom" [clearable]="false" formControlName="role" placeholder="Vyberte roli" [loading]="rolesLoading"></ng-select>
                <div *ngIf="submitted && f.role.errors">
                    <p *ngIf="f.role.errors.required" class="text-danger validation-text">
                        <span>{{ 'angular_validation.field' | translate }} </span>
                        <span>{{ 'employees.role' | translate }} </span>
                        <span>{{ 'angular_validation.required' | translate }}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="!employee" class="mt-2 mb-3"/>

    <div *ngIf="!employee" class="row">
        <div class="col-6">
            <label *ngIf="form.controls.is_active?.value === true; else notactiveTemplate" class="control-label">{{ 'employees.require_data_update_active' | translate }}</label>
            <ng-template #notactiveTemplate> <label class="control-label">{{ 'employees.require_data_update' | translate }}</label> </ng-template>
        </div>
        <div class="col-6 text-right align-self-center">
            <div>
                <input [checked]="showRequireDataUpdate" data-switch="success" id="required_data_update" name="required_data_update" type="checkbox">
                <label (click)="setShowRequireUpdate()">&nbsp;</label>
            </div>
        </div>
    </div>

    <div *ngIf="showRequireDataUpdate"
    >
        <div *ngIf="!employee" class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="control-label">
                        <span>{{ 'employees.selected_widget_template' | translate }}</span>
                    </label>

                    <ng-select
                        [multiple]="false"
                        [closeOnSelect]="true"
                        [items]="widgetTemplates$ | async"
                        [clearable]="false"
                        bindLabel="name"
                        bindValue="id"
                        class="custom"
                        formControlName="widget_template_ID"
                        (change)="widgetTemplateChanged($event)"
                    >
                    </ng-select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label for="require_update_message" class="control-label">
                        <span>{{ 'employees.message_for_employee' | translate }}</span>
                    </label>
                    <input
                        [ngxSummernote]="summernoteHelper.getSummernoteConfig(100)"
                        id="require_update_message"
                        class="form-control"
                        name="require_update_message"
                        formControlName="require_update_message"
                        type="text"
                    >
                </div>
            </div>
        </div>
    </div>

    <hr *ngIf="!employee" class="mt-2 mb-3">

    <div *ngIf="showActive && !employee" class="row">
        <div class="col-6">
            <label class="control-label">{{ 'employees.is_active' | translate }}</label>
        </div>
        <div class="col-6 text-right align-self-center">
            <div>
                <input data-switch="success" formControlName="is_active" id="is_active" name="is_active" type="checkbox">
                <label for="is_active">&nbsp;</label>
            </div>
        </div>
        <div *ngIf="form.controls.is_active.value" class="col-12 text-right">
            <small class="text-muted">
                {{ 'employees.is_active_text' | translate }}
            </small>
            <small *ngIf="(subscription$ | async)?.subscription.per_seats_pricing" class="text-muted">
                {{ 'subscription.subscription_price_may_change' | translate }}
            </small>
        </div>
    </div>

    <app-custom-fields *ngIf="employee?.custom_fields"
                       [customFields]="employee.custom_fields"
                       [sensitive]="false"
                       [submitted]="submitted"
                       [fieldset]="false"
                       [hr]="true"
                       [columns]="1"
                       [form]="form">
    </app-custom-fields>

    <div *ngIf="emailTaken || personalCodetaken" class="row">
        <div class="col">
            <div *ngIf="emailTaken" class="text-danger">{{ 'users.email_taken' | translate }}</div>
            <div *ngIf="personalCodetaken" class="text-danger">{{ 'employees.personal_code_taken' | translate }}</div>
        </div>
    </div>
</form>
