import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ID } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, merge, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { CompanyGroup } from '../../../../company-group/state/company-group.model';
import { CompanyGroupService } from '../../../../company-group/state/company-group.service';
import { Role } from '../../../../setting/models/Role';
import { RolesService } from '../../../../setting/services/roles.service';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { UserService } from '../../../../setting/services/user.service';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { Employee } from '../../../models/Employee';
import { EmployeeService } from '../../../services/employee.service';
import { EmployeeListType } from '../../../types';

interface CreatableEmployeeType extends Omit<'archived', EmployeeListType> {}

@Component({
    selector: 'app-employee-form',
    templateUrl: './employee-form.component.html',
    styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent implements OnInit {
    @Input() public submitted = false;
    @Input() public isQuestionnaire = false;
    @Input() public employee: Employee;
    @Input() public questionnaireEmployeeObject: Employee;

    @Input()
    public set employeeType(employeeType: CreatableEmployeeType) {
        this._employeeType = employeeType;

        if (this._employeeType !== 'COMMON') {
            this.showActive = false;
            this.showRole = false;
        } else {
            this.showActive = true;
            this.showRole = true;
        }

        this._updateValidation();
    }

    public get employeeType(): CreatableEmployeeType {
        return this._employeeType;
    }

    public showRole = true;
    public showActive = true;
    public companyGroupsAsEmployee$: Observable<Array<CompanyGroup>>;
    public companyGroupsAsOwner$: Observable<Array<CompanyGroup>>;
    public widgetTemplates$: Observable<Array<WidgetTemplate>>;
    public form: UntypedFormGroup;
    public emailTaken = false;
    public personalCodetaken = false;
    public showRequireDataUpdate = false;
    public employeeRoles$: Observable<Array<Role>>;
    public subscription$ = this._subscriptionService.subscription$;
    public companyGroups$: Observable<Array<CompanyGroup>>;
    public rolesLoading = true;
    public companyGroupsLoading = true;
    public companyGroupScope = null;

    private _companyGroupIdAsEmployee$ = new ReplaySubject<ID>(1);
    private _companyGroupIdsAsOwner$ = new ReplaySubject<Array<ID>>(1);
    private _fetchGroups$ = new ReplaySubject<void>(1);
    private _inputTimeout: any;
    private _debounceTime = 500;

    private _employeeType: CreatableEmployeeType = 'COMMON';

    constructor(
        public summernoteHelper: SummernoteHelper,
        private _fb: UntypedFormBuilder,
        private _roleService: RolesService,
        private _userService: UserService,
        private _subscriptionService: SubscriptionService,
        private _companyGroupService: CompanyGroupService,
        private _employeeService: EmployeeService,
        private _widgetTemplateService: WidgetTemplatesService,
        private _translateService: TranslateService,
    ) { }

    public get f() {
        return this.form.controls;
    }

    public ngOnInit(): void {
        this.form = this._fb.group({
            company_group_employee_ID: [null],
            company_group_owner_IDs: [null],
            email: ['', [
                Validators.required,
                Validators.email
            ]],
            name: ['', Validators.required],
            employee_type: [this.employeeType, Validators.required],
            phone: [''],
            surname: ['', Validators.required],
            born_surname: [''],
            personal_code: [''],
            abbreviation: [''],
            degree_before: [''],
            degree_after: [''],
            facebook: [''],
            linkedin: [''],
        });

        if (this.employee) {
            this.showRequireDataUpdate = false;
            if (this.isQuestionnaire && this.questionnaireEmployeeObject) {
                this.form.patchValue(this.questionnaireEmployeeObject);
            } else {
                this.form.patchValue({
                    company_group_employee_ID: this.employee.company_group?.company_group_ID,
                    company_group_owner_IDs: this.employee.owned_company_groups?.map(group => group.company_group_ID),
                    email: this.employee.email,
                    name: this.employee.name,
                    employee_type: this.employee.employee_type,
                    phone: this.employee.phone,
                    surname: this.employee.surname,
                    born_surname: this.employee.born_surname,
                    personal_code: this.employee.personal_code,
                    abbreviation: this.employee.abbreviation,
                    degree_before: this.employee.degree_before,
                    degree_after: this.employee.degree_after,
                    facebook: this.employee.facebook,
                    linkedin: this.employee.linkedin,
                });
            }
        } else {
            this._updateValidation();
            this.form.addControl('is_active', this._fb.control(this.employeeType === 'COMMON', Validators.required));
            this.companyGroupScope = 'onlyAvailable=true';
        }

        this.widgetTemplates$ = this._widgetTemplateService.getAllTemplates('select')
            .pipe(map(templates => {
                templates.push({id: 0, name: this._translateService.instant('widget_template.no_template'), message: null});
                if (this._widgetTemplateService.defaultWidgetTemplate?.id) {
                    this.form.patchValue({
                        widget_template_ID: this._widgetTemplateService.defaultWidgetTemplate.id,
                        require_update_message: this._widgetTemplateService.defaultWidgetTemplate.message
                    });
                }
                return templates;
            }));

        this.employeeRoles$ = this._roleService.getAllRoles()
            .pipe(
                map(roles => {
                    const employeeRoles = roles.filter(role => role.guard_type === 'employee');
                    if (!this.employee) {
                        const employeeRole = roles.find(role => role.internal_name === 'employee');
                        this.form.controls.role?.setValue(employeeRole.role_ID);
                        this.rolesLoading = false;
                    }
                    return employeeRoles;
                })
            );

        this.companyGroups$ = this._fetchGroups$
            .pipe(
                switchMap(() => this._companyGroupService.getAll(this.companyGroupScope)),
                map(groups => {
                    this.form.patchValue({
                        company_group_ID: groups[0]?.company_group_ID || '',
                    });
                    this.companyGroupsLoading = false;
                    groups.push({company_group_ID: 0, name: this._translateService.instant('employees.no_company_group')} as CompanyGroup);
                    return groups;
                }),
                shareReplay()
            );

        this.companyGroupsAsEmployee$ = combineLatest([
            this.companyGroups$,
            merge(
                this._companyGroupIdsAsOwner$,
                this.f.company_group_owner_IDs.valueChanges
            )
        ])
            .pipe(
                map(([groups, groupIdsToExclude]) => groups.filter(group => groupIdsToExclude.indexOf(group.company_group_ID) === -1))
            );

        this.companyGroupsAsOwner$ = combineLatest([
            this.companyGroups$,
            merge(
                this._companyGroupIdAsEmployee$,
                this.f.company_group_employee_ID.valueChanges
            )
        ])
            .pipe(
                map(([groups, groupIdToExclude]) => groups.filter(group => group.company_group_ID !== groupIdToExclude))
            );

        this._companyGroupIdAsEmployee$.next(this.employee?.company_group?.company_group_ID || null);
        this._companyGroupIdsAsOwner$.next(this.employee?.owned_company_groups?.map(group => group.company_group_ID) || []);

        this._updateValidation();

        this._fetchGroups$.next();
    }

    public inputChanged($event): void {
        clearTimeout(this._inputTimeout);
        this._inputTimeout = setTimeout(() => {
            if ($event.target.value !== '') {
                this.checkDuplicity($event.target.name, $event.target.value);
            }
        }, this._debounceTime);
    }

    public checkDuplicity(name, value): void {
        let promise;

        if (name === 'personal_code') {
            promise = this._employeeService.checkPropertyExistence(name, value);
        } else {
            promise = this._userService.checkPropertyExistence(name, value);
        }

        promise
            .then((response: any) => {
                if (name === 'email') {
                    this.emailTaken = response.disable_continue && (typeof this.employee === undefined || this.employee?.user_ID !== response.user_ID);
                }
                if (name === 'personal_code') {
                    this.personalCodetaken = response.disable_continue && response.employee_ID && this.employee?.employee_ID !== response.employee_ID;
                }
            })
            .catch(() => { });
    }

    public setShowRequireUpdate(): void {
        this.showRequireDataUpdate = !this.showRequireDataUpdate;
        if (this.showRequireDataUpdate) {
            this.form.addControl('widget_template_ID', this._fb.control(0));
            this.form.addControl('require_update_message', this._fb.control(''));
        } else {
            this.form.removeControl('widget_template_ID');
            this.form.removeControl('require_update_message');
        }
    }

    public widgetTemplateChanged($event: WidgetTemplate): void {
        this.form.patchValue({require_update_message: $event.message});
    }

    private _updateValidation(): void {
        if (!this.form) {
            return;
        }

        if (this.showActive) {
            this.form.controls.is_active?.setValidators([Validators.required]);
        } else {
            this.form.controls.is_active?.setValidators([]);
        }

        if (!this.employee) {
            this.form.addControl('role', this._fb.control(null));
            if (this._employeeType === 'COMMON') {
                this.form.addControl('company_group_ID', this._fb.control('', Validators.required));
                this.form.controls.role?.setValidators([Validators.required]);
            }
        }
    }
}
