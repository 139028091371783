import { Injectable } from '@angular/core';
import { Czech } from 'flatpickr/dist/l10n/cs';
import { english } from 'flatpickr/dist/l10n/default';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { Observable, ReplaySubject } from 'rxjs';
import { AvailableLocaleType } from '../types';

@Injectable({
    providedIn: 'root'
})
export class FlatpickrLocaleService {
    public currentFlatpickrLocale$: Observable<any>;

    private _currentFlatpickrLocale$$ = new ReplaySubject<any>(1);

    public constructor() {
        this.currentFlatpickrLocale$ = this._currentFlatpickrLocale$$;
    }

    public setLocale(locale: AvailableLocaleType): void {
        if (locale === 'cs') {
            this._currentFlatpickrLocale$$.next(Czech);
        } else if (locale === 'sk') {
            this._currentFlatpickrLocale$$.next(Slovak);
        } else {
            this._currentFlatpickrLocale$$.next(english);
        }
    }
}
