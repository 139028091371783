    <form  (ngSubmit)="onSubmit()"
           [formGroup]="companyBasicSettingEditForm"
           autocomplete="off"
    >
        <div class="row">
            <div class="col-md-12">
                <div class="card shadow">
                    <app-help name="settings.company.info"></app-help>

                    <div class="card-header py-3">
                        <div class="row">
                            <div class="col-sm-6 text-left my-auto">
                                <p class="modal-title font-16 text-dark pt-2 pb-2">
                                    <span>{{ 'global.basic_info' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="companySettings$ | async; else loadingtemplate" class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.name' | translate }}</span>
                                    </label>
                                    <input class="form-control" formControlName="company.info.label" name="company.info.label" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.email' | translate }}</span>
                                    </label>
                                    <input class="form-control" formControlName="company.info.email" name="company.info.email" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.phone' | translate }}</span>
                                    </label>
                                    <input class="form-control" formControlName="company.info.phone" name="company.info.phone" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label w-100">
                                        <span>{{ 'settings.company.info.in' | translate }}</span>
                                        <button (click)="showAresModal()" class="btn btn-link text-muted p-0 float-right" type="button">
                                            <u>{{'settings.load_ares' | translate}}</u>
                                        </button>
                                    </label>
                                    <input class="form-control" formControlName="company.info.in" name="company.info.in" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.tin' | translate }}</span>
                                    </label>
                                    <input class="form-control" formControlName="company.info.tin" name="company.info.tin" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.registration_text' | translate }}</span>
                                    </label>
                                    <input class="form-control" formControlName="company.info.registration_text" name="company.info.registration_text" type="text"/>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.description' | translate }}</span>
                                    </label>
                                    <textarea class="form-control" formControlName="company.info.description" name="company.info.description" type="text"></textarea>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">
                                        <span>{{ 'settings.company.info.registered_for_vat-1' | translate }}</span>
                                    </label>
                                    <div>
                                        <input data-switch="success" formControlName="company.info.registered_for_vat" id="company.info.registered_for_vat" name="company.info.registered_for_vat" type="checkbox">
                                        <label for="company.info.registered_for_vat">&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col d-flex justify-content-end">
                                <button class="btn btn-success">
                                    <span>{{ 'global.action_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
<ng-template #loadingtemplate>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="12"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
