import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KPI } from 'src/app/kpi/kpi/state/kpi.model';
import { KPIService } from 'src/app/kpi/kpi/state/kpi.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
    selector: 'app-create-edit-kpi-modal',
    templateUrl: './create-edit-kpi-modal.component.html',
    styleUrls: ['./create-edit-kpi-modal.component.css']
})
export class CreateEditKpiModalComponent implements OnInit {
    public kpiEditForm: UntypedFormGroup;
    public kpi: KPI;
    public kpiType = 'KPI';
    public recruitmentID: number;
    public submitted = false;
    public canDelete = false;
    public helperActive = false;
    public buttonText = 'chat.action_send';

    constructor(
        public activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _kpiService: KPIService,
        private _authService: AuthenticationService
    ) { }

    public get metrics(): FormArray {
        return this.kpiEditForm?.get('metrics') as FormArray;
    }

    public ngOnInit(): void {
        this.canDelete = this._authService.checkPermission('kpi.delete');
        this.kpiEditForm = this._fb.group({
            name: [this.kpiType === 'RECRUITMENT' ? 'evaluation_recruitment_' + this.recruitmentID : '', Validators.required],
            threshold_count: ['NONE'],
            threshold_1: ['33.34'],
            threshold_2: ['66.66']
        });

        if (this.kpi) {
            let thresholdCount = 'NONE';
            if (this.kpi.threshold_2) {
                thresholdCount = '2';
            } else if (this.kpi.threshold_1) {
                thresholdCount = '1';
            }

            this.kpiEditForm.patchValue({
                name: this.kpi.name,
                threshold_1: this.kpi.threshold_1,
                threshold_2: this.kpi.threshold_2,
                threshold_count: thresholdCount
            });
        } else {
            this.kpiEditForm.addControl('metrics', this._fb.array([
                this._fb.group({
                    name: [null, Validators.required]
                }),
            ]));
        }
    }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.kpiEditForm.controls;
    }

    public onSubmit(): void {
        this.submitted = true;
        if (!this.kpi) {
            this.removeEmptyMetrics();
        }

        if (!this.kpiEditForm.valid) {
            return;
        }

        const form = this.kpiEditForm.value;
        if (this.kpiType !== 'KPI') {
            form.recruitment_ID = this.recruitmentID;
            form.kpi_type = this.kpiType;
        }

        if (this.kpi) {
            this._kpiService.saveKPI(form, this.activeModal, this.kpi.kpi_ID);
        } else {
            this._kpiService.saveKPI(form, this.activeModal);
        }

    }

    public deleteKPI(): void {
        void this._kpiService.deleteKPI(this.kpi.kpi_ID);
    }

    public addMetric(metric = null): void {
        this.metrics.push(this._fb.group({
            name: [metric?.name, Validators.required]
        }));
    }

    public removeMetric(index: number): void {
        this.metrics.removeAt(index);
    }

    public patchMetricsToForm(metrics: { name: string }[], replace = true): void {
        if (replace) {
            this.metrics.clear();
        } else {
            this.removeEmptyMetrics();
        }

        metrics.forEach(metric => {
            this.addMetric(metric);
        });
        this.addMetric();
    }

    public removeEmptyMetrics(): void {
        this.metrics.controls.forEach((control, index) => {
            if (control.invalid) {
                this.metrics.removeAt(index);
            }
        });
    }
}
