import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AddressFormComponent } from '../../../../employee/components/embedded-forms/address/address-form.component';
import { ContactPersonFormComponent } from '../../../../employee/components/embedded-forms/contact-person/contact-person-form.component';
import { PersonalDataFormComponent } from '../../../../employee/components/embedded-forms/personal-data/personal-data-form.component';
import { SummernoteHelper } from '../../../../shared/common/SummernoteHelper';
import { snakeToCamelCase } from '../../../../shared/functions';
import { CustomField } from '../../../../shared/models/CustomField';
import { WidgetTemplate } from '../../../../shared/models/WidgetTemplate';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';

interface Control {
    name: string;
    value: string;
    disabled: boolean;
    label: string;
}

interface Controls {
    [key: string]: Control[]; // personal_data, address, contact_person, files
}

@Component({
    selector: 'app-create-edit-widget-template-modal',
    templateUrl: './create-edit-widget-template-modal.component.html',
    styleUrls: ['./create-edit-widget-template-modal.component.css']
})

export class CreateEditWidgetTemplateModalComponent implements OnInit, AfterViewInit {
    @ViewChild('personalDataFormComponent', {static: false}) personalDataFormComponent: PersonalDataFormComponent;
    @ViewChild('addressFormComponent', {static: false}) addressFormComponent: AddressFormComponent;
    @ViewChild('contactPersonFormComponent', {static: false}) contactPersonFormComponent: ContactPersonFormComponent;

    @Input() public customFieldDefinitions: CustomField[];

    public widgetTemplate: WidgetTemplate;
    public submitted = false;
    public collapsedForms = {personal_data: true, address: true, contact_person: true, files: true};
    public controls: Controls = {personal_data: [], address: [], contact_person: [], files: []};
    public controlOptions = [
        {value: 'VISIBLE', label: this._translateService.instant('settings.appearance.widget_templates.type_VISIBLE')},
        {value: 'HIDDEN', label: this._translateService.instant('settings.appearance.widget_templates.type_HIDDEN')},
        {value: 'REQUIRED', label: this._translateService.instant('settings.appearance.widget_templates.type_REQUIRED')},
        {value: 'READONLY', label: this._translateService.instant('settings.appearance.widget_templates.type_READONLY')},
    ];

    constructor(
        public activeModal: NgbActiveModal,
        public summernoteHelper: SummernoteHelper,
        private _widgetTemplateService: WidgetTemplatesService,
        private _translateService: TranslateService,
        private _cd: ChangeDetectorRef
    ) { }

    public ngOnInit(): void {

    }

    public ngAfterViewInit(): void {
        this.presetForm();
    }

    public presetForm(): void {
        const defaultControls = {
            personal_data: Object.keys(this.personalDataFormComponent.form.controls),
            address: Object.keys(this.addressFormComponent.form.controls),
            contact_person: Object.keys(this.contactPersonFormComponent.form.controls),
            files: []
        };
        const keys = Object.keys(defaultControls);
        const controlsToRender: Controls = {personal_data: [], address: [], contact_person: [], files: []};

        keys.forEach((key: string) => {
            defaultControls[key].forEach((controlName: string) => {
                const defaultControl = this[snakeToCamelCase(key) + 'FormComponent'].form.get(controlName);
                const addedControl = {name: controlName, value: 'VISIBLE', disabled: false, label: this.getLabel(controlName, key)};
                if (defaultControl.hasValidator(Validators.required)) {
                    addedControl.disabled = true;
                    addedControl.value = 'REQUIRED';
                }
                if (this.widgetTemplate && this.widgetTemplate[key]) {
                    addedControl.value = this.widgetTemplate[key][controlName];
                }
                controlsToRender[key].push(addedControl);
            });

            if (key === 'personal_data') {
                const personalDataCustomFields = this.customFieldDefinitions.filter((customField: CustomField) => customField.widget === 'PERSONAL_DATA' && customField.is_visible);
                personalDataCustomFields.forEach((customField: CustomField) => {
                    const addedControl = {name: customField.custom_field_definition_ID, value: 'VISIBLE', disabled: false, label: customField.label};
                    if (!controlsToRender.personal_data[`custom_fields`]) {
                        controlsToRender.personal_data[`custom_fields`] = [];
                    }
                    if (this.widgetTemplate?.[key]?.[`custom_fields`]?.[customField.custom_field_definition_ID]) {
                        addedControl.value = this.widgetTemplate[key][`custom_fields`][customField.custom_field_definition_ID];
                    }
                    controlsToRender[key][`custom_fields`].push(addedControl);
                });

            }
            this.collapsedForms[key] = this.widgetTemplate ? this.widgetTemplate[key] === false : true;
        });

        this.controls = controlsToRender;
        console.log(this.controls);
        this._cd.detectChanges();
    }

    public onSubmit(form: NgForm): void {
        this.submitted = true;

        if (form.invalid) {
            return;
        }

        const data = {
            name: form.form.getRawValue().name,
            message: form.form.getRawValue().message,
            personal_data: this.collapsedForms.personal_data ? false : form.form.getRawValue().personal_data,
            address: this.collapsedForms.address ? false : form.form.getRawValue().address,
            contact_person: this.collapsedForms.contact_person ? false : form.form.getRawValue().contact_person,
            files: !this.collapsedForms.files,
            employee: false,
            address_description: form.form.getRawValue().address?.address_description,
            contact_person_description: form.form.getRawValue().contact_person?.contact_person_description,
            personal_data_description: form.form.getRawValue().personal_data?.personal_data_description,
            employee_description: form.form.getRawValue().employee?.employee_description,
            files_description: form.form.getRawValue().files?.files_description
        } as any;

        delete data[`personal_data`]?.personal_data_description;
        delete data[`address`]?.address_description;
        delete data[`contact_person`]?.contact_person_description;
        delete data[`employee`]?.employee_description;
        delete data[`files`]?.files_description;

        this._widgetTemplateService.saveWidgetTemplate(data, this.widgetTemplate?.id)
            .then(() => {
                this.activeModal.close('close');
            });
    }

    public deleteWidgetTemplate(): void {
        this._widgetTemplateService.deleteWidgetTemplate(this.widgetTemplate?.id)
            .then(() => this.activeModal.close('close')).catch(() => {});
    }

    public getLabel(controlName: string, entity: string): string {
        if (entity === 'contact_person') {
            return this._translateService.instant('employees_contact_persons.' + controlName);
        }
        if (entity === 'personal_data') {
            return this._translateService.instant('employees.' + controlName);
        }
        return this._translateService.instant('employees_addresses.' + controlName);
    }

    public customOrder = (a: any, b: any): number => {
        if (a.key === 'files') { return 1; }  // 'files' bude vždy poslední
        if (b.key === 'files') { return -1; }
        return a.key.localeCompare(b.key); // standardní abecední řazení
    };
}
