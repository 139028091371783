<div class="modal-content">
  <div class="modal-header">
    <p
      *ngIf="review"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_reviews.review_detail' | translate }}</p>

    <p
      *ngIf="!review"
      class="modal-title font-16 mb-0 text-dark"
    >{{ 'employees_reviews.page_title_edit' | translate }}</p>

    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="close"
    >
      <span
        aria-hidden="true"
        class="font-24 text-muted"
      >
        <i class="mdi mdi-close"></i>
      </span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form
              #f="ngForm"
              (ngSubmit)="onSubmit(f)"
              *ngIf="!review"
              autocomplete="off"
            >

              <app-ai-helper *ngIf="showAiHelper" [scope]="'RECRUITMENT_EVALUATION'" [buttonText]="buttonText" [helperActive]="recruitmentID !== null" [employeeID]="employeeID" [recruitmentID]="recruitmentID" [formPatchFn]="patchEvaluationToForm.bind(this, f)"></app-ai-helper>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.rating' | translate }}</span>
                    </label>
                    <div class="btns-check-wrap">
                      <span *ngFor="let rate of availableRatings; let i = index;" class="btn-check-wrap">
                        <input
                          [checked]="i == 2"
                          autocomplete="off"
                          class="btn-check"
                          id="{{ 'rating_' + i }}"
                          name="rating"
                          ngModel="{{showAllRatings ? 3 : null}}"
                          type="radio"
                          value="{{ i + 1 }}"
                          >
                        <label
                          class="btn btn-outline-secondary"
                          for="{{ 'rating_' + i }}">
                          <img src="{{ 'assets/svg/emoji_rating/' + rate + '.svg' }}" />
                        </label>
                      </span>


                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.description' | translate }}</span>
                    </label>
                    <input
                      [ngxSummernote]="summernoteHelper.getSummernoteConfig(100)"
                      class="form-control"
                      name="description"
                      ngModel
                      rows="3"
                      type="textarea"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.pros' | translate }}</span>
                    </label>
                    <input
                      [ngxSummernote]="summernoteHelper.getSummernoteConfig(100)"
                      class="form-control"
                      name="pros"
                      ngModel
                      rows="3"
                      type="textarea"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.cons' | translate }}</span>
                    </label>
                    <input
                      [ngxSummernote]="summernoteHelper.getSummernoteConfig(100)"
                      class="form-control"
                      name="cons"
                      ngModel
                      rows="3"
                      type="textarea"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.date' | translate }}</span>
                    </label>
                    <input
                        (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                        allowInput="true"
                        altFormat="d.m.Y"
                        altInput="true"
                        class="form-control"
                        dateFormat="Y-m-d"
                        mwlFlatpickr
                        [locale]="locale$ | async"
                        name="date"
                        ngModel
                        type="text"
                    >
                  </div>
                </div>
              </div>

              <ng-container [ngModelGroup]="'kpis'">
                <ng-container
                  *ngFor="let k of kpis"
                  [ngModelGroup]="k.kpi_ID"
                >
                  <hr class="mt-3 mb-3" />

                  <div class="d-flex justify-content-between align-items-center">
                    <p class="modal-title font-16 mb-0 text-dark mb-3">{{ k.name }}</p>
                    <div>
                      <input (change)="collapseKpi($event, k.kpi_ID)" [checked]="openedKpis[k.kpi_ID]" data-switch="success" id="collapsed-1-{{k.kpi_ID}}" type="checkbox">
                      <label for="collapsed-1-{{k.kpi_ID}}">&nbsp;</label>
                    </div>
                  </div>
                  <div [(ngbCollapse)]="!openedKpis[k.kpi_ID]">
                    <app-radar-chart *ngIf="kpiRadarCharts$ | async; let data"
                                     [categories]="data[k.kpi_ID]?.categories"
                                     [height]="250"
                                     [series]="data[k.kpi_ID]?.series">
                    </app-radar-chart>

                    <ng-container *ngFor="let m of k.metrics; last as isLast">
                      <div
                        [ngModelGroup]="m.kpi_metric_ID"
                        class="row mb-2"
                      >
                        <div class="col-12">
                          <div class="form-group">
                            <label class="control-label mb-1">
                              <span>{{ m.name }}</span>

                              <span *ngIf="m.type !== 'text'">
                                <span>(</span>
                                <span>{{ 'employees_reviews.value_min' | translate }}</span>
                                <span>:&nbsp;</span>
                                <span>{{ m.min }}</span>
                                <span>, </span>
                                <span>{{ 'employees_reviews.value_max' | translate }}</span>
                                <span>:&nbsp;</span>
                                <span>{{ m.max }}</span>
                                <span>, </span>
                                <span>{{ 'employees_reviews.value_weight' | translate }}</span>
                                <span>:&nbsp;</span>
                                <span>{{ m.weight }}%</span>
                                <span>)</span>
                              </span>
                            </label>
                              <div class="mb-2">{{ m.description }}</div>

                            <ng-container *ngIf="m.type === 'binominal'">
                              <div class="custom-control custom-radio">
                                <input
                                  class="custom-control-input"
                                  id="YES-{{ m.kpi_metric_ID }}"
                                  name="value_rating-{{ m.kpi_metric_ID }}"
                                  ngModel
                                  type="radio"
                                  value="1"
                                >

                                <label
                                  class="custom-control-label font-weight-normal"
                                  for="YES-{{ m.kpi_metric_ID }}"
                                >{{ 'global.YES' | translate }}</label>
                              </div>

                              <div class="custom-control custom-radio">
                                <input
                                  class="custom-control-input"
                                  id="NO-{{ m.kpi_metric_ID }}"
                                  name="value_rating-{{ m.kpi_metric_ID }}"
                                  ngModel
                                  type="radio"
                                  value="0"
                                >

                                <label
                                  class="custom-control-label font-weight-normal"
                                  for="NO-{{ m.kpi_metric_ID }}"
                                >{{ 'global.NO' | translate }}</label>
                              </div>
                            </ng-container>

                            <input
                              *ngIf="m.type === 'text'"
                              class="form-control mb-2"
                              name="value_text-{{ m.kpi_metric_ID }}"
                              ngModel
                              type="text"
                            >

                            <ngx-slider
                              #value="ngModel"
                              (userChangeEnd)="sliderChangeEnd()"
                              *ngIf="m.type === 'rating'"
                              [options]="{ floor: m.min, ceil: m.max, showTicksValues: false }"
                              name="value_rating-{{ m.kpi_metric_ID }}"
                              ngModel
                            ></ngx-slider>

                            <input
                              *ngIf="m.type === 'number'"
                              [max]="m.max"
                              [min]="m.min"
                              class="form-control mb-2"
                              name="value_rating-{{ m.kpi_metric_ID }}"
                              ngModel
                              type="number"
                            >
                            <!--<div *ngIf="submitted && f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors">
                              <p *ngIf="f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors.min" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }}
                                {{ 'angular_validation.cannot_be_lower' | translate }} {{ m.min }}
                              </p>
                              <p *ngIf="f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors.max" class="text-danger validation-text">
                                {{ 'angular_validation.field' | translate }}
                                {{ 'angular_validation.cannot_be_greater' | translate }} {{ m.max }}
                              </p>
                            </div>-->
                            <hr *ngIf="!isLast" />
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>

              <div class="row mt-2 justify-content-end">
                <div class="col-6 text-right">
                  <button [disabled]="!f.controls.rating?.value || loading" class="btn btn-success">
                    <div *ngIf="loading" class="spinner-border spinner-border-sm avatar custom-avatar-loader" role="status">
                    </div>
                    <span *ngIf="!loading">{{ 'kpis.action_save' | translate }}</span>
                  </button>
                </div>
              </div>
            </form>

            <form
              #f="ngForm"
              (ngSubmit)="onSubmit(f)"
              *ngIf="review"
              autocomplete="off"
            >

              <div class="row justify-content-center text-center mb-3">
                <div class="col-9">
                  <p class="modal-title font-16 mb-2 mt-3 text-dark font-weight-bold">
                    <span>{{ 'employees_kpis.total_score' | translate }}</span>
                    <span>:&nbsp;</span>
                    <span>{{ review.score | number: '1.0-2' }}</span>
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.rating' | translate }}</span>
                    </label>
                    <div class="btns-check-wrap">
                      <span *ngFor="let rate of availableRatings; let i = index;" class="btn-check-wrap">
                        <input
                          [checked]="(i + 1) == review.rating"
                          autocomplete="off"
                          class="btn-check"
                          disabled
                          id="{{ 'rating_' + i }}"
                          name="rating"
                          type="radio"
                          value="{{ i + 1 }}"
                          >
                        <label
                          class="btn btn-outline-secondary"
                          for="{{ 'rating_' + i }}">
                          <img src="{{ 'assets/svg/emoji_rating/' + rate + '.svg' }}" />
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.description' | translate }}</span>
                    </label>

                    <textarea
                      [(ngModel)]="review.description"
                      [disabled]="true"
                      class="form-control"
                      name="description"
                      rows="3"
                      type="textarea"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.pros' | translate }}</span>
                    </label>

                    <textarea
                      [(ngModel)]="review.pros"
                      [disabled]="true"
                      class="form-control"
                      name="pros"
                      rows="3"
                      type="textarea"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.cons' | translate }}</span>
                    </label>

                    <textarea
                      [(ngModel)]="review.cons"
                      [disabled]="true"
                      class="form-control"
                      name="cons"
                      rows="3"
                      type="textarea"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label class="control-label">
                      <span>{{ 'employees_reviews.date' | translate }}</span>
                    </label>
                    <input
                        (flatpickrClose)="fpHelper.applyAltInputValue($event, false)"
                        [(ngModel)]="review.date"
                        [disabled]="true"
                        allowInput="true"
                        altFormat="d.m.Y"
                        altInput="true"
                        class="form-control"
                        dateFormat="Y-m-d"
                        mwlFlatpickr
                        [locale]="locale$ | async"
                        name="date"
                        ngModel
                        type="text"
                    >
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="control-label mb-0">
                    <span>{{ 'employees_reviews.show_details' | translate }}</span>
                  </label>
                </div>

                <div class="col-6 text-right align-self-center">
                  <div>
                    <input
                      data-switch="success"
                      id="show_more"
                      name="show_more"
                      type="checkbox"
                      [checked]="showMore"
                      (change)="showMore = !showMore"
                    >
                    <label for="show_more">&nbsp;</label>
                  </div>
                </div>
              </div>

                <ng-container
                  *ngIf="showMore"
                  [ngModelGroup]="'kpis'"
                >
                  <ng-container
                    *ngFor="let k of review.kpis"
                    [ngModelGroup]="k.kpi_ID.toString()"
                  >
                    <hr class="mt-3 mb-3" />

                    <div class="d-flex justify-content-between align-items-center">
                      <p class="modal-title font-16 mb-0 text-dark mb-3">
                        <span>{{ k.name }}</span>
                        <span>(</span>
                        <span>{{ 'employees_reviews.value_min' | translate }}</span>
                        <span>:&nbsp;</span>
                        <span>{{ k.min_score | number: '1.0-2' }}</span>
                        <span>, </span>
                        <span>{{ 'employees_reviews.value_max' | translate }}</span>
                        <span>:&nbsp;</span>
                        <span>{{ k.max_score | number: '1.0-2' }}</span>
                        <span>)</span>
                      </p>
                      <div>
                        <input (change)="collapseKpi($event, k.kpi_ID)" [checked]="openedKpis[k.kpi_ID]" data-switch="success" id="collapsed-2-{{k.kpi_ID}}" type="checkbox">
                        <label for="collapsed-2-{{k.kpi_ID}}">&nbsp;</label>
                      </div>
                    </div>

                    <div *ngIf="openedKpis[k.kpi_ID]">
                      <app-radar-chart *ngIf="kpiRadarCharts$ | async; let data"
                              [categories]="data[k.kpi_ID]?.categories"
                              [height]="250"
                              [series]="data[k.kpi_ID]?.series">
                      </app-radar-chart>

                      <fieldset [disabled]="true">
                      <ng-container *ngFor="let m of k.metrics; last as isLast">
                        <div
                          [ngModelGroup]="m.kpi_metric_ID.toString()"
                          class="row mb-2"
                        >
                          <div class="col-12">
                            <div class="form-group">
                              <label class="control-label mb-1">
                                <span>{{ m.name }}</span>

                                <span *ngIf="m.type !== 'text'">
                                  <span>(</span>
                                  <span>{{ 'employees_reviews.value_min' | translate }}</span>
                                  <span>:&nbsp;</span>
                                  <span>{{ m.min }}</span>
                                  <span>, </span>
                                  <span>{{ 'employees_reviews.value_max' | translate }}</span>
                                  <span>:&nbsp;</span>
                                  <span>{{ m.max }}</span>
                                  <span>, </span>
                                  <span>{{ 'employees_reviews.value_weight' | translate }}</span>
                                  <span>:&nbsp;</span>
                                  <span>{{ m.weight }}%</span>
                                  <span>)</span>
                                </span>
                              </label>
                                <div class="mb-2">{{ m.description }}</div>

                              <ng-container *ngIf="m.type === 'binominal'">
                                <div class="custom-control custom-radio">
                                  <input
                                    [ngModel]="m.value_rating"
                                    class="custom-control-input"
                                    id="YES-{{ m.kpi_metric_ID }}"
                                    name="value_rating-{{ m.kpi_metric_ID }}"
                                    type="radio"
                                    value="1"
                                  >

                                  <label
                                    class="custom-control-label font-weight-normal"
                                    for="YES-{{ m.kpi_metric_ID }}"
                                  >{{ 'global.YES' | translate }}</label>
                                </div>

                                <div class="custom-control custom-radio">
                                  <input
                                    [ngModel]="m.value_rating"
                                    class="custom-control-input"
                                    id="NO-{{ m.kpi_metric_ID }}"
                                    name="value_rating-{{ m.kpi_metric_ID }}"
                                    type="radio"
                                    value="0"
                                  >

                                  <label
                                    class="custom-control-label font-weight-normal"
                                    for="NO-{{ m.kpi_metric_ID }}"
                                  >{{ 'global.NO' | translate }}</label>
                                </div>
                              </ng-container>

                              <input
                                *ngIf="m.type === 'text'"
                                [ngModel]="m.value_text"
                                class="form-control mb-2"
                                name="value_text-{{ m.kpi_metric_ID }}"
                                type="text"
                              >

                              <ngx-slider
                                (userChangeEnd)="sliderChangeEnd()"
                                *ngIf="m.type === 'rating'"
                                [ngModel]="m.value_rating"
                                [options]="{ floor: m.min, ceil: m.max, showTicksValues: false, disabled: true }"
                                name="value_rating-{{ m.kpi_metric_ID }}"
                              ></ngx-slider>

                              <input
                                *ngIf="m.type === 'number'"
                                [max]="m.max"
                                [min]="m.min"
                                [ngModel]="m.value_rating"
                                class="form-control mb-2"
                                name="value_rating-{{ m.kpi_metric_ID }}"
                                type="number"
                              >
                              <!--<div *ngIf="submitted && f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors">
                                <p *ngIf="f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors.min" class="text-danger validation-text">
                                  {{ 'angular_validation.field' | translate }}
                                  {{ 'angular_validation.cannot_be_lower' | translate }} {{ m.min }}
                                </p>
                                <p *ngIf="f.controls.kpis?.controls[k.kpi_ID]?.controls[m.kpi_metric_ID]?.controls['value_rating-' + m.kpi_metric_ID.toString()]?.errors.max" class="text-danger validation-text">
                                  {{ 'angular_validation.field' | translate }}
                                  {{ 'angular_validation.cannot_be_greater' | translate }} {{ m.max }}
                                </p>
                              </div>-->

                              <label
                                *ngIf="m.type !== 'text'"
                                class="control-label"
                              >
                                <span>{{ 'employees_reviews.result_percentage' | translate }}</span>
                                <span>:&nbsp;</span>

                                <span
                                  *ngIf="m.threshold_count === 2"
                                  [ngClass]="{
                                    'text-danger': m.percent_score <= m.threshold_1,
                                    'text-warning':  m.percent_score > m.threshold_1 && m.percent_score <= m.threshold_2,
                                    'text-green': m.percent_score > m.threshold_2
                                  }"
                                >
                                  <span>{{ m.percent_score | number: '1.0-2' }}</span>
                                  <span>%</span>
                                </span>

                                <span
                                  *ngIf="m.threshold_count === 1"
                                  [ngClass]="{
                                    'text-danger': m.percent_score <= m.threshold_1,
                                    'text-green': m.percent_score > m.threshold_1
                                  }"
                                >
                                  <span>{{ m.percent_score | number: '1.0-2' }}</span>
                                  <span>%</span>
                                </span>

                                <span *ngIf="m.threshold_count == 0">
                                  <span>{{ m.percent_score | number: '1.0-2' }}</span>
                                  <span>%</span>
                                </span>
                              </label>

                              <hr *ngIf="!isLast" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      </fieldset>
                    </div>
                    <p>
                      <strong>
                        <span>{{ 'employees_reviews.result_percentage_total' | translate }}</span>
                        <span>:&nbsp;</span>

                        <span
                          *ngIf="k.threshold_count == 2"
                          [ngClass]="{
                            'text-danger': k.percent_score <= k.threshold_1,
                            'text-warning':  k.percent_score > k.threshold_1 && k.percent_score <= k.threshold_2,
                            'text-green': k.percent_score > k.threshold_2
                          }"
                        >
                          <span>{{ k.percent_score | number: '1.0-2' }}</span>
                          <span>%</span>
                        </span>

                        <span
                          *ngIf="k.threshold_count == 1"
                          [ngClass]="{
                            'text-danger': k.percent_score <= k.threshold_1,
                            'text-green': k.percent_score > k.threshold_1
                          }"
                        >
                          <span>{{ k.percent_score | number: '1.0-2' }}</span>
                          <span>%</span>
                        </span>

                        <span *ngIf="k.threshold_count == 0">
                          <span>{{ k.percent_score | number: '1.0-2' }}</span>
                          <span>%</span>
                        </span>
                      </strong>
                    </p>
                  </ng-container>
                  </ng-container>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                  <button (click)="deleteReview()" *ngIf="canDelete$ | async" class="btn btn-link text-muted pr-0" type="button">
                    <u>{{ 'kpis.action_delete' | translate }}</u>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
