import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { HelpStore } from 'src/app/help/state/help.store';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { AppService } from 'src/app/shared/services/app.service';
import { HelpService } from 'src/app/shared/services/help.service';
import { AvailableFeatures } from '../../../../employee/models/AvailableFeatures';
import { NotificationService } from '../../../../notification/services/notification.service';
import { CompanyService } from '../../../../setting/services/company.service';
import { localStorageSafe } from '../../../../shared/functions';
import { WidgetTemplatesService } from '../../../../shared/services/widget-templates.service';
import { LoggedInUser } from '../../../models/LoggedUser';
import { DisableDemoModeModalComponent } from '../../disable-demo-mode-modal/disable-demo-mode-modal.component';

interface Alert {
    type: string;
    message: string;
    closeable: boolean;
}

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
    @ViewChild('contentSubNotPaidModal', {static: true})
    public contentSubNotPaidModal: TemplateRef<ElementRef>;

    @ViewChild('preActivationModal')
    public preActivationModal: TemplateRef<ElementRef>;

    public loggedUser: LoggedInUser;
    public alerts: Array<Alert> = [];
    public isOnline = true;
    public closeable = false;
    public canEditSubscription = false;
    public preActivationModalRef: NgbModalRef | null = null;
    public companySettings: any;
    public showMarketingAgreement = false;
    public availableFeatures$: Observable<AvailableFeatures>;
    public year = new Date().getFullYear().toString();
    public notPaidAlternativeModal = false;

    private _demoAlertShown = false;
    private _importAlertShown = false;
    private _demoModeActive = false;
    private _subNotPaidModalOpened = false;
    private _termsModalOpened = false;
    private _subscriptions = new Subscription();

    public constructor(
        public translateService: TranslateService,
        private _appService: AppService,
        private _notificationService: NotificationService,
        private _authService: AuthenticationService,
        private _helpService: HelpService,
        private _helpStore: HelpStore,
        private _modalService: NgbModal,
        private _subscriptionService: SubscriptionService,
        private _companyService: CompanyService,
        private _router: Router,
        private _widgetTemplatesService: WidgetTemplatesService,
    ) { }

    public ngOnDestroy(): void {
        this._subscriptions?.unsubscribe();
    }

    public ngOnInit(): void {
        this._subscriptions.add(this._notificationService.getNotificationSettings()
            .subscribe());

        this._subscriptions.add(this._subscriptionService.getAvailableFeatures()
            .subscribe());

        this._subscriptions.add(this._companyService.getAvailableWidgets()
            .subscribe());

        this._subscriptions.add(this._widgetTemplatesService.getWidgetTemplateByID()
            .subscribe());

        this._subscriptions.add(this._helpService.getHelpData()
            .subscribe());

        this._subscriptions.add(combineLatest([
            this._authService.loggedUser$,
            combineLatest([
                this._subscriptionService.getDataMode(),
                this._subscriptionService.subscription$,
            ])
                .pipe(map(([noop, indirect]) => indirect)),
            combineLatest([
                this._appService.canShowBasicSettingsModal$,
                this._appService.canShowUsersToBeActivatedMessage$,
            ])
                .pipe(map(([basicSettingsModal, usersToBeActivatedMessage]) => ({basicSettingsModal, usersToBeActivatedMessage}))),
            this._authService.hasPermissionTo('subscription.edit')
                .pipe(map(perm => perm.can)),
            this._companyService.getCompanySetting()
                .pipe(map(settings => ({
                    address: settings?.company_settings?.address,
                    in: settings?.company_settings?.info?.in,
                    name: settings?.company_settings?.info?.name,
                    tin: settings?.company_settings?.info?.tin,
                })))
        ])
            .subscribe(
                ([user, subscriptionData, canShow, canEditSubscription, companySettings]) => {
                    if (
                        user &&
                        user.is_admin
                    ) {
                        this._demoModeActive = subscriptionData.demo_mode;

                        if (
                            this._demoAlertShown !== true &&
                            this._demoModeActive
                        ) {
                            this.alerts.push({
                                type: 'primary',
                                message: 'demo.alert_text',
                                closeable: true
                            });

                            this._demoAlertShown = true;
                        }

                        if (!this._demoModeActive && subscriptionData.payable_employees === 0 && !this._importAlertShown) {
                            this.alerts.push({
                                type: 'warning',
                                message: 'subscription.import_alert_employees',
                                closeable: true
                            });

                            this._importAlertShown = true;
                        }

                        this._helpStore.update({help_mode: subscriptionData.help_mode});
                    }

                    this.availableFeatures$ = this._subscriptionService.availableFeatures$;

                    this.loggedUser = user;

                    this.companySettings = companySettings;

                    const isImpersonationLogin = localStorageSafe.getItem('impersonation_admin') === 'true';
                    // terms agree modal
                    if (!isImpersonationLogin) {
                        if (subscriptionData.terms_need_to_agree && !this._termsModalOpened && !document.body.classList.contains('modal-open')) {
                            this.preActivationModalRef = this._modalService.open(this.preActivationModal, {
                                backdrop: 'static',
                                centered: true,
                                keyboard: false
                            });

                            this.showMarketingAgreement = subscriptionData.marketing_agreed === false;
                            this._termsModalOpened = true;
                        }
                    }

                    this.closeable = window.location.href.includes('subscription-settings');

                    this.canEditSubscription = canEditSubscription;

                    // not paid modal
                    if (!isImpersonationLogin && user && subscriptionData.subscription.status === 'NOT_PAID' && !this._subNotPaidModalOpened && !document.body.classList.contains('modal-open')) {
                        if (!this.closeable || (this.closeable && !window.location.href.includes('notPaidClicked=true'))) {
                            if (subscriptionData.subscription.package === 'BASIC' && user.is_admin) {
                                this.notPaidAlternativeModal = true;
                            }
                            const modalRef = this._modalService.open(this.contentSubNotPaidModal, {
                                backdrop: 'static',
                                centered: true,
                                keyboard: false
                            });

                            this._subNotPaidModalOpened = true;
                        }
                    }
                }));

        this.checkNetworkStatus();
    }

    public checkNetworkStatus() {
        this._subscriptions.add(merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        )
            .pipe(map(() => navigator.onLine))
            .subscribe(status => {
                this.isOnline = status;
                if (this.isOnline) {
                    this.alerts = this.alerts.filter(alert => alert.message !== 'global.no_internet_connection');
                } else {
                    this.alerts.push({
                        type: 'danger',
                        message: 'global.no_internet_connection',
                        closeable: false
                    });
                }
            }));
    }

    public disableDemoMode(): void {
        const modalRef = this._modalService.open(DisableDemoModeModalComponent, {centered: true});
        modalRef.result
            .then(
                disabled => disabled ? this._subscriptionService.switchData() : null,
                () => { }
            );
    }

    public agreeTerms(terms1: boolean, marketingAgreed: boolean, modal = null): void {
        if (modal) {
            modal.close();
        }
        if (!this.showMarketingAgreement) {
            marketingAgreed = null;
        }
        this._subscriptionService.agreeTerms(terms1, marketingAgreed);
    }

    public editSubscription(modal = null): void {
        if (modal) {
            modal.close();
        }
        this._subNotPaidModalOpened = false;
        this._router.navigate(['/setting/subscription-settings'], {queryParams: {notPaidClicked: true}});
    }

    public close(alert: Alert): void {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }
}
