  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span *ngIf="mode === 'full'">
            <ng-container *ngIf="('activity_log.subjects.' + selectedActivityLog.subject_type) !== (('activity_log.subjects.' + selectedActivityLog.subject_type) | translate); else noTranslationTemplate">
                                        {{ ('activity_log.subjects.' + selectedActivityLog.subject_type) | translate }}
            </ng-container>
            <ng-template #noTranslationTemplate>
                {{ selectedActivityLog.subject_type }}
            </ng-template>
            -
          {{ ('activity_log.events.' + (selectedActivityLog.event || selectedActivityLog.description)) | translate }}
        </span>
          <span *ngIf="mode === 'simple'">
            {{ selectedActivityLog.employee.fullname }}
          </span>
      </p>

      <button
        aria-label="Close"
        class="close"
        (click)="activeModal.dismiss()"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-0 shadow-none">
            <div class="card-body p-0">
              <form #f="ngForm">
                <div *ngIf="mode === 'full'" class="row mb-4">
                  <div class="col-12">
                    <label class="control-label">
                        {{ 'activity_log.object' | translate }}
                    </label><br/>
                    {{ selectedActivityLog.label }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div *ngIf="mode === 'full'" class="form-group text-left">
                      <div class="row border-bottom mb-1">
                        <div class="col-4"></div>
                        <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.old_value' | translate }}</label></div>
                        <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.new_value' | translate}}</label></div>
                      </div>
                      <div *ngFor="let newItem of selectedActivityLog.new | keyvalue" class="row border-bottom mb-1">
                        <div class="col-4 text-dark">
                          <label *ngIf="newItem.key" class="control-label">{{ newItem.key }}</label>
                        </div>
                          <div class="col-4"><span *ngIf="selectedActivityLog.old"> {{ selectedActivityLog.old[newItem.key] }} </span></div>
                        <div class="col-4">{{ newItem.value }}</div>
                      </div>
                    </div>
                      <div *ngIf="mode === 'simple'" class="form-group text-left">
                          <ng-container *ngFor="let event of selectedActivityLog.events">
                              <h4 class="my-3 d-flex justify-content-between align-items-center">
                                  <span>{{ ('activity_log.subjects.' + event.subject_type) | translate }} - {{ ('activity_log.events.' + event.event) | translate }}</span>
                                  <button *ngIf="!event.is_processed"
                                          (click)="processUnprocessRow(event)"
                                          class="btn btn-white btn-icon btn-sm mr-1"
                                  >
                                      <i class="mdi mdi-checkbox-blank-circle-outline mdi-24px text-muted"></i>
                                  </button>
                                  <button *ngIf="event.is_processed"
                                          (click)="processUnprocessRow(event, false)"
                                          class="btn btn-white btn-icon btn-sm mr-1"
                                  >
                                      <i class="mdi mdi-check-circle-outline mdi-24px text-green"></i>
                                  </button>
                              </h4>
                              <div class="row border-bottom mb-1">
                                  <div class="col-4"></div>
                                  <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.old_value' | translate }}</label></div>
                                  <div class="col-4 text-dark"><label class="control-label">{{ 'activity_log.new_value' | translate}}</label></div>
                              </div>
                              <div *ngFor="let newItem of event.new | keyvalue" class="row border-bottom mb-1">
                                  <div class="col-4 text-dark">
                                      <label *ngIf="newItem.key" class="control-label">{{ ('employees.' + newItem.key) | translate }}</label>
                                  </div>
                                  <div class="col-4"><span *ngIf="event.old"> {{ getTranslatedValue(event.old[newItem.key]) }} </span></div>
                                  <div class="col-4">{{ getTranslatedValue(newItem.value) }}</div>
                              </div>
                          </ng-container>
                      </div>
                  </div>
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
