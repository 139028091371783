import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { EmployeeActivity } from 'src/app/employee/models/EmployeeActivity';
import { File } from 'src/app/employee/models/File';
import { EmployeeService } from 'src/app/employee/services/employee.service';
import { FlatpickrHelper } from 'src/app/shared/common/FlatpickrHelper';
import { FlatpickrLocaleService } from 'src/app/shared/services/flatpickr-locale.service';

@Component({
    selector: 'app-create-edit-employee-activity',
    templateUrl: './create-edit-employee-activity-modal.component.html',
    styleUrls: ['./create-edit-employee-activity-modal.component.css']
})
export class CreateEditEmployeeActivityModalComponent implements OnInit {
    public canEdit$: Observable<boolean>;
    public employeeID: number;
    public files$: Observable<Array<File>>;
    public form: UntypedFormGroup;
    public locale$ = this._flatpickrLocale.currentFlatpickrLocale$;
    public submitted = false;
    public type = 'EMPLOYEE';

    private _fetchFiles$ = new ReplaySubject<void>(1);
    private _activity: EmployeeActivity | undefined;

    public constructor(
        public activeModal: NgbActiveModal,
        public fpHelper: FlatpickrHelper,
        private _authService: AuthenticationService,
        private _employeeService: EmployeeService,
        private _fb: UntypedFormBuilder,
        private _flatpickrLocale: FlatpickrLocaleService,
    ) { }

    public get f(): { [formControlName: string]: AbstractControl; } {
        return this.form.controls;
    }

    public get activity(): EmployeeActivity | undefined {
        return this._activity;
    }

    public set activity(activity: EmployeeActivity) {
        this._activity = activity;
    }

    public ngOnInit(): void {
        this.canEdit$ = this._authService.hasPermissionTo('employeeActivity.edit')
            .pipe(map(permission => permission.can));

        this.files$ = this._fetchFiles$
            .pipe(switchMap(() => this._employeeService.getAllFilesByActivity(this.activity)));

        this.form = this._fb.group({
            public: [false],
            date: ['', Validators.required],
            description: [''],
            name: ['', Validators.required],
            type: [this.type === 'EMPLOYEE' ? 'NOTE' : 'INTERVIEW', Validators.required],
            notify: [false]
        });

        if (this.activity) {
            this._prefillForm(this.activity);
        }
    }

    public deleteActivity(): void {
        this._employeeService.deleteActivity(this.employeeID, this.activity.employee_activity_ID)
            .then(() => this.activeModal.close('close'))
            .catch(() => { });
    }

    public fetchFiles(): void {
        this._fetchFiles$.next();
    }

    public onSubmit(): void {
        this.submitted = true;

        if (this.form.valid) {
            this._employeeService.saveActivity(this.form.value, this.employeeID, this.activity?.employee_activity_ID)
                .then(() => this.activeModal.close('close'));
        }
    }

    private _prefillForm(activity: EmployeeActivity): void {
        if (activity) {
            this.form.patchValue(this.activity);
            this.fetchFiles();
        }
    }
}
