import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActivityLog } from 'src/app/shared/models/ActivityLog';
import { ActivityLogService } from '../../../services/activity-log.service';

@Component({
    selector: 'app-activity-log-detail-modal',
    templateUrl: './activity-log-detail-modal.component.html',
    styleUrls: ['./activity-log-detail-modal.component.css']
})
export class ActivityLogDetailModalComponent {

    public selectedActivityLog: ActivityLog;
    public mode: string;
    public refetchFn: () => void;

    public constructor(
        public activeModal: NgbActiveModal,
        public translateService: TranslateService,
        private _activityLogService: ActivityLogService
    ) { }

    public getTranslatedValue(value: any): string {
        if (value === false) {
            return this.translateService.instant('global.NO');
        }
        if (value === true) {
            return this.translateService.instant('global.YES');
        }
        if (value === 'UNDEFINED') {
            return this.translateService.instant('employees.gender_UNDEFINED');
        }

        return value;
    }

    public processUnprocessRow(row: any, process = true): void {
        row.is_processed = process;
        this._activityLogService.setProcessedUnprocessed([row.id], process)
            .then(() => this.refetchFn());
    }
}
