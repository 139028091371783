import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PromptResponse } from '../../../../chat/state/chat.model';
import { ChatService } from '../../../../chat/state/chat.service';
import { RecruitmentService } from '../../../../recruitment/services/recruitment.service';
import { Subscription } from '../../../../setting/models/Subscription';
import { SubscriptionService } from '../../../../setting/services/subscription.service';
import { SwalHelper } from '../../../common/SwalHelper';

@Component({
    selector: 'app-ai-helper',
    templateUrl: './ai-helper.component.html',
    styleUrls: ['./ai-helper.component.css']
})
export class AiHelperComponent implements OnInit {
    @Input() public scope: 'EVALUATION' | 'ACTIVITY' | 'FORM' | 'RECRUITMENT' | 'RECRUITMENT_EVALUATION' | 'RECRUITMENT_ADVERTISEMENT';
    @Input() public formGroup: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public formPatchFn: (value: any, replace?: boolean) => void;
    @Input() public recruitmentID?: number;
    @Input() public employeeID?: number;
    @Input() public helperActive = false;
    @Input() public buttonText = 'chat.action_send';

    public aiForm: UntypedFormGroup;
    public promptResponse: PromptResponse;
    public submitted = false;
    public subscriptionInfo$: Observable<Subscription>;

    constructor(
        private _fb: UntypedFormBuilder,
        private _chatService: ChatService,
        private _swalHelper: SwalHelper,
        private _susbcriptionService: SubscriptionService,
        private _recruitmentService: RecruitmentService,
    ) {}

    public ngOnInit(): void {
        this.subscriptionInfo$ = this._susbcriptionService.subscription$;

        this.aiForm = this._fb.group({
            scope: [this.scope],
            message: ['', Validators.required],
            meta: this._fb.group({
                title: ['', Validators.required],
            })
        });

        // patch message because the form is not valid without it
        if (this.scope === 'RECRUITMENT' || this.scope === 'RECRUITMENT_EVALUATION') {
            this.aiForm.patchValue({
                message: 'recruitment'
            });
        }
    }

    public async onSubmit(subscriptionInfo: Subscription): Promise<void> {
        this.submitted = true;

        this.aiForm.patchValue({
            meta: {
                title: this.formGroup?.value?.name
            }
        });

        if (this.aiForm.invalid && this.scope !== 'RECRUITMENT_EVALUATION' && this.scope !== 'RECRUITMENT_ADVERTISEMENT') {
            return;
        }

        let promise = null;
        if (this.scope === 'RECRUITMENT') {
            promise = this._recruitmentService.proposeEvaluation(this.recruitmentID);
        } else if (this.scope === 'RECRUITMENT_EVALUATION') {
            promise = this._recruitmentService.evaluateApplicant(this.recruitmentID, this.employeeID);
        } else if (this.scope === 'RECRUITMENT_ADVERTISEMENT') {
            promise = this._recruitmentService.generateAdvertisementContent(this.aiForm.value.message, this.recruitmentID);
        } else {
            promise = this._chatService.newPrompt(this.aiForm.value);
        }

        promise.then((response: PromptResponse) => {
            const newSubscriptionInfo = {...subscriptionInfo} as Subscription;
            newSubscriptionInfo.ai.available_credits = response.remaining_credits;

            this._susbcriptionService.updateSubscriptionInfo(newSubscriptionInfo);
            void this.patchValueLogic(response);
        });
    }

    public async patchValueLogic(response: PromptResponse): Promise<void> {
        let replaceConfirmed = true;
        let control = null;
        if (this.formGroup) {
            control = this.formGroup?.get(this.controlName);
            const shouldNotAsk = control.pristine && !this.promptResponse;
            replaceConfirmed = shouldNotAsk || await this._swalHelper.showConfirmChangeDialog('chat.replace_text');
        }
        this.promptResponse = response;

        if (typeof this.formPatchFn === 'function') {
            this.formPatchFn(this.promptResponse.message, replaceConfirmed);
            return;
        }

        if (replaceConfirmed) {
            this.formGroup.patchValue({[this.controlName]: this.promptResponse.message});
        } else {
            this.formGroup.patchValue({[this.controlName]: control?.value + ' ' + this.promptResponse.message});
        }
    }
}
