import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListParameters, ListResponse, transformParameters } from 'src/app/api';
import { HelpStore } from 'src/app/help/state/help.store';
import { ApiHelper } from 'src/app/shared/common/ApiHelper';
import { SwalHelper } from 'src/app/shared/common/SwalHelper';
import { ApiResponse } from 'src/app/shared/models/ApiResponse';
import { User } from 'src/app/user/models/User';
import { ImportableUser } from '../models/ImportableUser';

interface UserToBeImportedSync {
    action: number;
    data: {
        phone?: string;
        provider_id: string;
        provider_name: string;
    };
}

interface UserToBeImportedCreate {
    action: 'CREATE';
    data: {
        email: string;
        name: string;
        surname: string;
        is_employee: '1' | '0';
        phone?: string;
        provider_id: string;
        provider_name: string;
    };
}

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _helpStore: HelpStore,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper
    ) { }

    public deleteUser(userID: number): Promise<'done'> {
        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/users/' + userID)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            this._loaderService.stop();

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public getAvailableUsers(allowNoEmployee = false): Observable<Array<User>> {
        return this._http.get<Array<User>>('/api/available-users' + (allowNoEmployee ? '?allowNoEmployee' : ''));
    }

    public getAllFromAzure(): Observable<Array<ImportableUser>> {
        return this._http.get<Array<any>>('/api/azure/fetch-users')
            .pipe(
                map(users => users?.map(user => ({
                    emails: user.emails,
                    firstNames: user.first_names,
                    lastNames: user.last_names,
                    phones: user.phones,
                    providerId: user.provider_id
                })))
            );
    }

    public getUserByID(userId: number): Observable<User> {
        return this._http.get<User>('/api/users/' + userId);
    }

    public getUsersList(params: ListParameters<User>): Observable<ListResponse<User>> {
        return this._http.post<ListResponse<User>>('/api/users/list', {...transformParameters(params)});
    }

    public import(importableUsers: Array<any>, providerName: string): Observable<any> {
        return this._http.post('/api/auth/providers/create-update-users', this._transformToImport(importableUsers, providerName));
    }

    public saveSalutation(salutation: string, userId: number): Promise<'done'> {
        this._loaderService.start();
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/users/' + userId + '/change-salutation', {salutation})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public savePasswordByUserID(password: string, passwordConfirmation: string, userId: number): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/users/' + userId + '/change-password', {password, password_confirmation: passwordConfirmation})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');

                        this._loaderService.stop();
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);

                        this._loaderService.stop();
                    }
                );
        });
    }

    public saveUser(form: User, userId?: number, withLoading = true): Promise<'done'> {
        if (withLoading) {
            this._loaderService.start();
        }

        return new Promise(resolve => {
            if (userId) {
                this._http.post<ApiResponse>('/api/users/' + userId, form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            } else {
                this._http.post<ApiResponse>('/api/users', form)
                    .subscribe(
                        response => {
                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');

                            this._loaderService.stop();
                        },
                        error => {
                            this._apiHelper.handleErrorResponse(error);

                            this._loaderService.stop();
                        }
                    );
            }
        });
    }

    public setHelpMode(helpMode: boolean, userId: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/users/' + userId + '/set-help-mode', {help_mode: helpMode})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({helpMode});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public setLocale(locale: string, userId: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/users/' + userId + '/set-locale', {locale})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._helpStore.update({locale});

                        resolve('done');
                    },
                    error => {
                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public toggleActiveUser(userId: number): Promise<'done'> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/users/${userId}/toggle-active`, {})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    public markWelcomeModalViewed(userID: number): void {
        this.saveMeta(userID, 'welcome_modal_viewed_at', 'now()', true);
    }

    public saveMeta(userID: number, key: string, value: string, showToast = false): void {
        this._http.post<ApiResponse>(`/api/users/${userID}/save-meta`, {key, value})
            .subscribe(
                response => {
                    if (showToast) {
                        this._apiHelper.handleSuccessResponse(response, 'bottom-end');
                    }
                },
                error => this._apiHelper.handleErrorResponse(error)
            );
    }

    public checkPropertyExistence(name: string, value: string): Promise<any> {
        return new Promise(resolve => {
            this._http.post<ApiResponse>(`/api/users/check-property-existence`, {name, value})
                .subscribe(
                    response => {

                        resolve(response);
                    },
                    error => this._apiHelper.handleErrorResponse(error)
                );
        });
    }

    private _transformToImport(importableUsers: Array<any>, providerName: string): Array<UserToBeImportedCreate | UserToBeImportedSync> {
        const PROVIDER_NAME = providerName.toLowerCase();
        let transformedUser: UserToBeImportedCreate | UserToBeImportedSync;

        return importableUsers.map(user => {
            if (user.value) { // sync
                transformedUser = {
                    action: user.value,
                    data: {
                        provider_id: user.providerId,
                        provider_name: PROVIDER_NAME
                    }
                };
            } else { // create
                transformedUser = {
                    action: 'CREATE',
                    data: {
                        email: user.email,
                        name: user.firstName,
                        surname: user.lastName,
                        is_employee: user.isEmployee ? '1' : '0',
                        provider_id: user.providerId,
                        provider_name: PROVIDER_NAME
                    }
                };
            }

            if (user.phone) {
                transformedUser.data.phone = user.phone;
            }

            return transformedUser;
        });
    }
}
