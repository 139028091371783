<ng-template
  #filterFormOptions
  let-modal
>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title font-16 mb-0 text-dark">
        <span>{{ 'employees.adjust_filters' | translate }}</span>
      </p>

      <button
        (click)="modal.dismiss()"
        aria-label="Close"
        class="close"
      >
        <span
          aria-hidden="true"
          class="font-24 text-muted"
        >
          <i class="mdi mdi-close"></i>
        </span>
      </button>
    </div>

    <div class="modal-body">
      <form
        (ngSubmit)="onSubmit()"
        [formGroup]="filterForm"
        class="filter-form"
      >
        <fieldset *ngIf="false">
          <legend>{{ 'employees.filter_section_in_company' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_date_from' | translate }}</label>

                <input
                  [locale]="locale$ | async"
                  allowInput="true"
                  altFormat="d.m.Y"
                  altInput="true"
                  class="form-control"
                  dateFormat="Y-m-d"
                  formControlName="from"
                  mwlFlatpickr
                  type="text"
                >
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_date_to' | translate }}</label>

                <input
                  [locale]="locale$ | async"
                  allowInput="true"
                  altFormat="d.m.Y"
                  altInput="true"
                  class="form-control"
                  dateFormat="Y-m-d"
                  formControlName="to"
                  mwlFlatpickr
                  type="text"
                >
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'employees.filter_section_company_groups' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_company_groups' | translate }}</label>

                <ng-select
                  [bindLabel]="'name'"
                  [bindValue]="'company_group_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="companyGroups$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="companyGroups"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_groups_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('companyGroups') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'employees.filter_section_employee_branch' | translate }}</legend>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_company_branch' | translate }}</label>

                <ng-select
                  [bindLabel]="'name'"
                  [bindValue]="'company_branch_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="companyBranches$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="mainCompanyBranches"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('mainCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                    <span>&nbsp;</span>
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'employees.filter_section_employee_position' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_employee_position_types' | translate }}</label>

                <ng-select
                  [bindLabel]="'name'"
                  [bindValue]="'name'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="employeePositionTypes$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="employeePositionTypes"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_employee_position_types_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('employeePositionTypes') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                    <span>&nbsp;</span>
                    <span>{{ 'employees_positions.' + item.name | translate }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_position_company_branch' | translate }}</label>

                <ng-select
                  [bindLabel]="'name'"
                  [bindValue]="'company_branch_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="companyBranches$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="positionCompanyBranches"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_position_company_branch_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('positionCompanyBranches') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                    <input [checked]="item$.selected" id="item-{{index}}" type="checkbox"/>
                    <span>&nbsp;</span>
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>

            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>{{ 'employees.filter_employee_states' | translate }}</label>

                        <ng-select
                            [bindLabel]="'label'"
                            [bindValue]="'value'"
                            [clearable]="false"
                            [closeOnSelect]="false"
                            [items]="positionPeriodsSelect"
                            [multiple]="true"
                            class="custom"
                            formControlName="position_periods"
                        >
                            <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_employee_states_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('position_periods') }}</span>
                      <span>)</span>
                    </span>
                            </ng-template>

                            <ng-template
                                let-index="index"
                                let-item="item"
                                let-item$="item$"
                                ng-option-tmp
                            >
                                <input
                                    [checked]="item$.selected"
                                    id="item-{{index}}"
                                    type="checkbox"
                                />

                                <span>&nbsp;</span>
                                <span>{{ item.label }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'employees.filter_section_tags' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_tags' | translate }}</label>

                <ng-select
                  [bindLabel]="'label'"
                  [bindValue]="'tag_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="tags$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="tags"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_tags_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('tags') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{ 'employees.filter_section_skills' | translate }}</legend>

          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_hard_skills' | translate }}</label>

                <ng-select
                  [bindLabel]="'label'"
                  [bindValue]="'tag_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="hardSkillsTags$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="hardSkillsTags"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_hard_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('hardSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_soft_skills' | translate }}</label>

                <ng-select
                  [bindLabel]="'label'"
                  [bindValue]="'tag_ID'"
                  [clearable]="false"
                  [closeOnSelect]="false"
                  [items]="softSkillsTags$ | async"
                  [multiple]="true"
                  class="custom"
                  formControlName="softSkillsTags"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                      class="ng-placeholder"
                      style="display: block;"
                    >
                      <span>{{ 'employees.filter_soft_skills_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('softSkillsTags') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                    let-index="index"
                    let-item="item"
                    let-item$="item$"
                    ng-option-tmp
                  >
                    <input
                      [checked]="item$.selected"
                      id="item-{{index}}"
                      type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset *ngIf="canEditEmployee || canViewOnboarding || canViewEmployee">
          <legend>{{ 'employees.filter_employee_states' | translate }}</legend>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label>{{ 'employees.filter_employee_states' | translate }}</label>

                <ng-select
                    [bindLabel]="'label'"
                    [bindValue]="'value'"
                    [clearable]="false"
                    [closeOnSelect]="false"
                    [items]="statesSelect"
                    [multiple]="true"
                    class="custom"
                    formControlName="states"
                >
                  <ng-template ng-multi-label-tmp>
                    <span
                        class="ng-placeholder"
                        style="display: block;"
                    >
                      <span>{{ 'employees.filter_employee_states_count' | translate }}</span>
                      <span> (</span>
                      <span>{{ getCount('states') }}</span>
                      <span>)</span>
                    </span>
                  </ng-template>

                  <ng-template
                      let-index="index"
                      let-item="item"
                      let-item$="item$"
                      ng-option-tmp
                  >
                    <input
                        [checked]="item$.selected"
                        id="item-{{index}}"
                        type="checkbox"
                    />

                    <span>&nbsp;</span>
                    <span>{{ item.label }}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </fieldset>

        <!--<app-custom-fields-filter *ngIf="customFieldColumns"
                                  [customFields]="customFieldColumns"
                                  [fieldset]="true"
                                  [form]="filterForm">
        </app-custom-fields-filter>-->

        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-primary">
              <span>{{ 'employees.action_filter' | translate }}</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<div *ngIf="rows && (availableWidgets$ | async)?.employee_table?.columns as availableWidgets; else loadingtemplate" class="row">
  <div class="col-12">
    <div class="card shadow">
      <app-help *ngIf="type === 'COMMON'" name="employee.list"></app-help>
      <app-help *ngIf="type === 'CONTRACTOR'" name="employee.list_externists"></app-help>
      <app-help *ngIf="type === 'POTENTIAL'" name="employee.list_potentials"></app-help>
      <app-help *ngIf="type === 'archived'" name="employee.list_archive"></app-help>

      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="table-responsive overflow-visible">
              <app-data-table
                (searchChanged)="searchChanged($event)"
                (sortChanged)="sortChanged($event)"
                [colsConfig]="type === 'POTENTIAL' ? null : {width: setColumnsWidth(availableWidgets)}"
                [disableInPlaceFiltering]="true"
                [disableInPlaceSorting]="true"
                [loading]="loading"
                [rows]="rows"
                [search]="search"
                [sort]="sort"
                [trackByFn]="trackByFn"
              >
                <ng-template
                  #headerButton
                  *ngIf="canCreateEmployee"
                >
                  <app-export [stream$]="employees$"></app-export>
                  <button  *ngIf="(!type || listTypeWithCreate.includes(type))"
                           (click)="openFilterFormOptions()"
                           class="btn btn-primary btn-icon ml-sm-2"
                           type="button"
                           [formGroup]="filterForm"
                  >
                    <i class="mdi mdi-filter-outline"></i>
                  </button>
                  <button *ngIf="(!type || listTypeWithCreate.includes(type))"
                          (click)="openEditModal()"
                          [createButton]="'employees.action_create' | translate"
                          class="ml-sm-2"
                  ></button>
                </ng-template>

                <ng-template #headerRow>
                  <th appDataTableHeaderCell="fullname">{{ 'employees.name' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.contact" appDataTableHeaderCell="contact">{{ 'employees.contact' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.main_branch" appDataTableHeaderCell="company_branch">{{ 'employees.main_company_branch' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.teams" appDataTableHeaderCell="company_group" >{{ 'employees.company_groups' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_type" appDataTableHeaderCell="position_type">{{ 'employees.position_type' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.test_time_end" appDataTableHeaderCell="test_time_end">{{ 'settings.appearance.employee_table.test_time_end' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_from" appDataTableHeaderCell="position_from">{{ 'settings.appearance.employee_table.position_from' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_to" appDataTableHeaderCell="position_to">{{ 'settings.appearance.employee_table.position_to' | translate }}</th>

                  <th *ngIf="type !== 'POTENTIAL' && availableWidgets?.in_company_from" appDataTableHeaderCell="in_company_from">{{ 'settings.appearance.employee_table.in_company_from' | translate }}</th>

                  <th *ngFor="let column of customFieldColumns" [appDataTableHeaderCell]="'custom_field.' + column.custom_field_definition_ID">{{ column.label }}</th>

                  <th
                    [disableSort]="true"
                    appDataTableHeaderCell
                    class="text-right"
                  >{{ 'employees.table_action' | translate }}</th>
                </ng-template>

                <ng-template
                  #dataRow
                  let-row
                >
                  <td
                    appDataTableDataCell
                    class=""
                  >
                    <app-employee-box-avatar [employee]="row"></app-employee-box-avatar>
                  </td>

                  <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.contact" appDataTableDataCell>{{ row.email }}<br>{{ row.phone }}</td>

                  <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.main_branch" appDataTableDataCell>{{ row.company_branch_name }}</td>

                  <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.teams" appDataTableDataCell>{{ row.companyGroupNames }}</td>

                  <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_type" appDataTableDataCell>
                    <ng-container *ngFor="let pos_type of row.position_types; let i = index">
                      {{pos_type.count > 1 ? pos_type.count + 'x ' : ''}}{{ ('employees_positions.'+(pos_type.name)) | translate }}<ng-container *ngIf="i < row.position_types.length - 1">, </ng-container>
                    </ng-container>
                  </td>

                    <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.test_time_end" appDataTableDataCell>{{ row.test_time_end | date: 'dd.MM.yyyy' }}</td>

                    <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_from" appDataTableDataCell>{{ row.position_from | date: 'dd.MM.yyyy' }}</td>

                    <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.position_to" appDataTableDataCell>{{ row.position_to | date: 'dd.MM.yyyy' }}</td>

                    <td *ngIf="type !== 'POTENTIAL' && availableWidgets?.in_company_from" appDataTableDataCell>{{ row.in_company_from | date: 'dd.MM.yyyy' }}</td>


                  <td *ngFor="let column of row?.custom_fields" appDataTableDataCell>
                      <ng-container *ngIf="column.type !== 'TEXTAREA' && column.type !== 'RICHTEXT'">
                          <ng-container [ngSwitch]="column.type">
                              <span *ngSwitchCase="'PERCENT'"> {{ column.value }} %</span>
                              <span *ngSwitchCase="'AMOUNT'"> {{ column.value | localizedCurrency }}</span>
                              <span *ngSwitchCase="'DATE'"> {{ column.value | date: 'dd.MM.yyyy' }}</span>
                              <span *ngSwitchCase="'DATETIME'"> {{ column.value | date: 'dd.MM.yyyy HH:mm' }}</span>
                              <span *ngSwitchCase="'TOGGLE'">  {{ (column.value ? 'global.YES' : 'global.NO') | translate }}</span>
                              <span *ngSwitchDefault>{{ column.value }}</span>
                          </ng-container>
                      </ng-container>
                  </td>

                  <td
                    appDataTableDataCell
                    class="text-right"
                  >
                    <a
                      *ngIf="row.permissions.view"
                      [fragment]="type !== 'COMMON' ? type : null"
                      [routerLink]="['/employee', row.employee_ID, 'detail', 'overview']"
                      class="btn btn-white btn-icon btn-sm mr-1"
                      title="{{ 'employees.action_view' | translate }}"
                    >
                      <i class="mdi mdi-eye-outline mdi-24px text-muted"></i>
                    </a>
                  </td>
                </ng-template>
              </app-data-table>
            </div>
          </div>
        </div>

        <div class="row mt-3 flex-column-reverse flex-sm-row">
          <div class="col-12 col-sm-2">
            <app-items-per-page-select
              (itemsPerPageChanged)="itemsPerPageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [total]="itemsTotal"
            ></app-items-per-page-select>
          </div>

          <div class="col-12 col-sm-10">
            <app-pagination
              (pageChanged)="pageChanged($event)"
              [itemsPerPage]="itemsPerPage"
              [page]="page"
              [total]="itemsTotal"
            ></app-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingtemplate>
    <div class="row">
        <div class="col-12">
            <div class="card shadow p-3">
                <div class="item">
                    <ngx-skeleton-loader [theme]="{
        'margin': '15px 0'
      }" appearance="line" count="7"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>

