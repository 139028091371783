<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">{{'integrations.page_title_edit' | translate}}</p>
        <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
            <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12 px">
                <div class="integration-modal-card mb-0 shadow-none">

                    <div class="row">
                        <div class="col-4 text-left">
                  <span class="integration-icon">
                    <img [alt]="integration.name" [src]="'assets/images/integrations/' + integration.logo_url"/>
                  </span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h4 class="mt-3 text-dark">{{ integration.name }}</h4>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="mt-1 text-muted" [innerHTML]="integration.description"></div>
                        </div>
                    </div>
                    <ng-container *ngIf="(integrationConfig$ | async) as integrationConfig; else loadingTemplate"
                                  [ngSwitch]="integration.key">
                        <form *ngSwitchCase="'microsoft-0365-login'" [formGroup]="microsoftAzureForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.microsoft-0365-login.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', microsoftAzureForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <h4>
                                            {{"integrations.settings" | translate}}
                                        </h4>
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.microsoft-0365-login.login_forced" | translate}} </label>
                                            <div>
                                                <input
                                                    [id]="integration.key + '-login_forced'"
                                                    data-switch="success"
                                                    formControlName="login_forced"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-login_forced'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.microsoft-0365-login.login_allowed_for_set_tenant_id_only" | translate}} </label>
                                            <div>
                                                <input
                                                    (click)="setCollapse('tenant_ID')"
                                                    [attr.aria-expanded]="!isSecondSectionCollapsed"
                                                    [id]="integration.key + '-login_allowed_for_set_tenant_id_only'"
                                                    aria-controls="collapseTenantID"
                                                    data-switch="success"
                                                    formControlName="login_allowed_for_set_tenant_id_only"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-login_allowed_for_set_tenant_id_only'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    #collapseTenantID="ngbCollapse"
                                    [(ngbCollapse)]="!isSecondSectionCollapsed"
                                >

                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="control-label"> {{"integrations.microsoft-0365-login.tenant_id" | translate}} </label>
                                                <div>
                                                    <input
                                                        class="form-control"
                                                        formControlName="tenant_ID"
                                                        name="tenant_ID"
                                                        type="text">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <form *ngSwitchCase="'google-login'" [formGroup]="googleLoginForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.google-login.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', googleLoginForm)"
                                            aria-controls="collapse"
                                            [id]="integration.key + '-is_active'"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                            [attr.aria-expanded]="!isCollapsed"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <h4>
                                            {{"integrations.settings" | translate}}
                                        </h4>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.google-login.login_forced" | translate}} </label>
                                            <div>
                                                <input
                                                    [id]="integration.key + '-login_forced'"
                                                    data-switch="success"
                                                    formControlName="login_forced"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-login_forced'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'slack'" [formGroup]="slackForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.slack.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            [id]="integration.key + '-is_active'"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!integrationConfig.is_active"
                                class="row"
                            >
                                <div class="col-12 mb-2">
                                    <a [ngClass]="{'pointer-events-none': slackStep !== null}" href="https://slack.com/oauth?client_id=404421291155.6932741910882&scope=channels:read,chat:write,chat:write.customize,groups:read,groups:write,im:read,incoming-webhook,mpim:write,users.profile:read,users:read,users:read.email&user_scope=&redirect_uri=https://app.speybl.com/setting/integration?open=slack&state=&granular_bot_scope=1&single_channel=0&install_redirect=&tracked=1&team="
                                       class="slack-btn w-100">
                                        <span *ngIf="slackStep === null"> {{ 'integrations.slack.sync_with_slack' | translate }} </span>
                                        <span *ngIf="slackStep !== null">
                                            <div *ngIf="slackStep === 1 || slackStep === 2" class="spinner-border spinner-border-sm mr-2 slack-loader" role="status">
                                            </div>
                                            {{ ('integrations.slack.loading_step_' + slackStep) | translate }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'apple-login'" [formGroup]="appleLoginForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.apple-login.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            [id]="integration.key + '-is_active'"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'calendar-export'" [formGroup]="calendarExportForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations."+integration.key+".is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', calendarExportForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <h4>
                                            {{"integrations.settings" | translate}}
                                        </h4>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <label class="control-label"> {{"integrations."+integration.key+".is_active_calendar" | translate}} </label>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <input
                                                (click)="setCollapse('events_active', calendarExportForm)"
                                                [attr.aria-expanded]="!calendarExportForm.value.config.events_active"
                                                [id]="integration.key + '-events_active'"
                                                aria-controls="collapse"
                                                data-switch="success"
                                                formControlName="events_active"
                                                type="checkbox"
                                            >
                                            <label [for]="integration.key + '-events_active'">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>

                                <div [(ngbCollapse)]="!calendarExportForm.value.config.events_active" class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.calendar-export.alert_type" | translate}} </label>
                                            <div>
                                                <ng-select
                                                    formControlName="alert_type"
                                                    class="custom"
                                                    bindLabel="label"
                                                    bindValue="value"
                                                    [clearable]="false"
                                                    [items]="calendarExportSelectableItems.alert_type"
                                                >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="integrationConfig.config?.calendar_url" [(ngbCollapse)]="!calendarExportForm.value.config.events_active" class="row">
                                    <div class="col-12">
                                        <label class="control-label m-0"> {{"integrations."+integration.key+".download_ics" | translate}} </label>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <a class="btn-link"
                                           href="{{'https://' + integrationConfig.config?.calendar_url}}"
                                           target="_blank">
                                            {{'https://' + integrationConfig.config?.calendar_url}}
                                        </a>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <label class="control-label"> {{"integrations."+integration.key+".is_active_absence" | translate}} </label>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <input
                                                (click)="setCollapse('absence_active', calendarExportForm)"
                                                [attr.aria-expanded]="!calendarExportForm.value.config.absence_active"
                                                [id]="integration.key + '-absence_active'"
                                                aria-controls="collapse"
                                                data-switch="success"
                                                formControlName="absence_active"
                                                type="checkbox"
                                            >
                                            <label [for]="integration.key + '-absence_active'">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="integrationConfig.config?.calendar_absence_url" [(ngbCollapse)]="!calendarExportForm.value.config.absence_active" class="row">
                                    <div class="col-12">
                                        <label class="control-label m-0"> {{"integrations."+integration.key+".download_ics" | translate}} </label>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <a class="btn-link"
                                           href="{{'https://' + integrationConfig.config?.calendar_absence_url}}"
                                           target="_blank">
                                            {{'https://' + integrationConfig.config?.calendar_absence_url}}
                                        </a>
                                    </div>
                                    <div class="col-12 text-right mb-2">
                                        <small class="text-muted">{{"integrations."+integration.key+".calendar_absence_url_description" | translate}}</small>
                                    </div>
                                </div>
                            </div>


                        </form>

                        <form *ngSwitchCase="'microsoft-teams'" [formGroup]="microsoftTeamsForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.microsoft-teams.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input (click)="setCollapse('is_active', microsoftTeamsForm)"
                                               [attr.aria-expanded]="!isCollapsed"
                                               [id]="integration.key + '-is_active'"
                                               aria-controls="collapse"
                                               data-switch="success"
                                               formControlName="is_active"
                                               type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                                <div [(ngbCollapse)]="!isCollapsed" class="col-12">
                                    <a [routerLink]="['setting/notification']">
                                        <u class="text-muted">
                                            {{"integrations.microsoft-teams.notification_settings" | translate}}
                                        </u>
                                    </a>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'microsoft-one-drive'" [formGroup]="microsoftOneDriveForm" autocomplete="off">
                            <div class="row mt-4 mb-2">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.microsoft-one-drive.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', microsoftOneDriveForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <button (click)="openMsalLogin()" *ngIf="!integrationConfig?.config?.email || integrationConfig?.config?.email === ''"
                                                class="microsoft-btn w-100"
                                                type="button">
                                            <div *ngIf="msalLoading" class="d-flex justify-content-around align-items-center">
                                                {{ 'public_pages.azure_loading_text' | translate }}
                                                <div class="spinner-border spinner-border-sm azure-loader" role="status">
                                                </div>
                                            </div>
                                            <span *ngIf="!msalLoading">
                                                 {{"integrations.microsoft-one-drive.connect" | translate}}
                                            </span>
                                        </button>
                                        <div *ngIf="integrationConfig?.config?.email && integrationConfig?.config?.email !== ''">
                                            <span>
                                                {{"integrations.microsoft-one-drive.unpair_text" | translate}} {{ integrationConfig?.config?.email }}.
                                            </span>
                                            <u (click)="onSubmit('unpair', true)">
                                                {{"integrations.microsoft-one-drive.unpair" | translate}}
                                            </u>.
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <h4>
                                            {{"integrations.microsoft-one-drive.upload_settings" | translate}}
                                        </h4>
                                    </div>
                                </div>
                                <ng-container formGroupName="upload">
                                    <div class="row mt-1">
                                        <div class="col-9">
                                            <label class="control-label"> {{"integrations.microsoft-one-drive.upload.employee_file" | translate}} </label>
                                        </div>
                                        <div class="col-3 text-right">
                                            <div>
                                                <input
                                                    [attr.aria-expanded]="!isCollapsed"
                                                    [id]="integration.key + '-employee_file'"
                                                    data-switch="success"
                                                    formControlName="employee_file"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-employee_file'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-9">
                                            <label class="control-label"> {{"integrations.microsoft-one-drive.upload.payroll" | translate}} </label>
                                        </div>
                                        <div class="col-3 text-right">
                                            <div>
                                                <input
                                                    [attr.aria-expanded]="!isCollapsed"
                                                    [id]="integration.key + '-payroll'"
                                                    data-switch="success"
                                                    formControlName="payroll"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-payroll'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-9">
                                            <label class="control-label"> {{"integrations.microsoft-one-drive.upload.internal_document" | translate}} </label>
                                        </div>
                                        <div class="col-3 text-right">
                                            <div>
                                                <input
                                                    [attr.aria-expanded]="!isCollapsed"
                                                    [id]="integration.key + '-internal_document'"
                                                    data-switch="success"
                                                    formControlName="internal_document"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-internal_document'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-9">
                                            <label class="control-label"> {{"integrations.microsoft-one-drive.upload.property_file" | translate}} </label>
                                        </div>
                                        <div class="col-3 text-right">
                                            <div>
                                                <input
                                                    [attr.aria-expanded]="!isCollapsed"
                                                    [id]="integration.key + '-property_file'"
                                                    data-switch="success"
                                                    formControlName="property_file"
                                                    type="checkbox"
                                                >
                                                <label [for]="integration.key + '-property_file'">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <hr class="mt-0">
                                <div class="row">
                                    <div class="col-9">
                                        <label class="control-label"> {{"integrations.microsoft-one-drive.sync_old_files" | translate}} </label>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <input
                                                [attr.aria-expanded]="!isCollapsed"
                                                [id]="integration.key + '-sync_old_files'"
                                                data-switch="success"
                                                formControlName="sync_old_files"
                                                type="checkbox"
                                            >
                                            <label [ngClass]="{'disabled-toggle': integrationConfig?.config?.sync_old_files}" [for]="integration.key + '-sync_old_files'">&nbsp;</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-9">
                                        <label class="control-label"> {{"integrations.microsoft-one-drive.sync_unsigned_signable" | translate}} </label>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <input
                                                [attr.aria-expanded]="!isCollapsed"
                                                [id]="integration.key + '-sync_unsigned_signable'"
                                                data-switch="success"
                                                formControlName="sync_unsigned_signable"
                                                type="checkbox"
                                            >
                                            <label [for]="integration.key + '-sync_unsigned_signable'">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="col-12 text-right">
                                        <small class="text-muted">
                                            {{ 'integrations.microsoft-one-drive.sync_unsigned_signable_info' | translate }}
                                        </small>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-2">
                                    <div class="col-12">
                                        <div class="alert alert-danger w-100 shadow">
                                        <span>
                                            {{ 'integrations.microsoft-one-drive.alert' | translate }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'giriton'" [formGroup]="giritonForm" autocomplete="off">
                            <div class="row">
                                <div class="col-12">
                                    <a class="btn-link"
                                       href="https://docs-cs.giriton.com/article/86-rest-api"
                                       target="_blank">
                                        https://docs-cs.giriton.com/article/86-rest-api
                                    </a>
                                </div>
                            </div>
                            <div class="row mt-4 mb-2">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.giriton.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', giritonForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                class="mt-2 mb-2"
                                formGroupName="config"
                            >
                                <ng-container formArrayName="api_keys">
                                    <ng-container *ngFor="let apiKeyForm of apiKeys.controls; let index = index">
                                        <div [formGroupName]="index" class="row mb-3">
                                            <div class="col-lg-7 col-12">
                                                <label class="control-label"> {{"integrations.api_key" | translate}} </label>
                                                <input class="form-control"
                                                       formControlName="api_key"
                                                       type="text"
                                                >
                                            </div>
                                            <div class="col-lg-4 col-11 mt-2 mt-lg-0">
                                                <label class="control-label"> {{"integrations.giriton.branch" | translate}} </label>
                                                <ng-select [items]="branchStoreService.branches$ | async"
                                                           [multiple]="false"
                                                           bindLabel="name"
                                                           bindValue="company_branch_ID"
                                                           class="custom"
                                                           formControlName="company_branch_ID">
                                                </ng-select>
                                            </div>
                                            <div class="col-1 d-flex align-items-end justify-content-end flex-column">
                                                <label class="control-label"> </label>
                                                <button (click)="removeApiKey(index)" class="btn btn-icon btn-primary" type="button">
                                                    <i class="mdi mdi-trash-can-outline mdi-24px"></i>
                                                </button>
                                            </div>
                                            <div *ngIf="submitted && apiKeyControl(index).errors" class=" col-12 mt-1">
                                                <p *ngIf="apiKeyControl(index).errors.required" class="text-danger">
                                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                                    <span>{{ 'integrations.api_key' | translate }} </span>
                                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                                </p>
                                            </div>
                                            <div *ngIf="apiKeyForm.value.api_key && !apiKeyForm.value.company_branch_ID" class="col-12 text-warning mt-1">
                                                {{ 'integrations.giriton.empty_branch_info' | translate}}
                                            </div>
                                        </div>

                                    </ng-container>
                                </ng-container>
                                <div class="row mb-3">
                                    <div class="col-12 text-right">
                                        <button (click)="addNewApiKey()" [createButton]="'add_api_key'" type="button"></button>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 text-right">
                                        <small class="text-muted">
                                            {{ 'integrations.giriton.sync_info' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'microsoft-todo'" [formGroup]="microsoftTodoForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-12">
                                    <label class="control-label"> {{'integrations.api_key' | translate}} </label>
                                    <input class="form-control"
                                           formControlName="api_key"
                                           name="api_key"
                                           type="text"
                                    >
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'kiosk-mode'" [formGroup]="kioskModeForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{'integrations.kiosk_mode.is_active' | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', kioskModeForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>
                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                class="mt-2 mb-2"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-9">
                                        <label class="control-label"> {{'integrations.kiosk_mode.simple' | translate}} </label>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div>
                                            <input
                                                [id]="integration.key + '-simple'"
                                                data-switch="success"
                                                formControlName="simple"
                                                type="checkbox"
                                            >
                                            <label [for]="integration.key + '-simple'">&nbsp;</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="text-right">
                                            <small class="text-muted">
                                                {{ 'integrations.kiosk_mode.simple_explanation' | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{'integrations.kiosk_mode.code_length' | translate}} </label>
                                            <input class="form-control"
                                                   formControlName="code_length"
                                                   name="code_length"
                                                   type="number"
                                                   min="4"
                                                   max="16"
                                            >
                                            <p *ngIf="submitted && kioskCodeLength?.min" class="text-danger validation-text">
                                                {{ 'angular_validation.field' | translate }} {{ 'integrations.kiosk_mode.code_length' | translate }}
                                                {{ 'angular_validation.cannot_be_lower' | translate }} 6
                                            </p>
                                            <p *ngIf="submitted && kioskCodeLength?.max" class="text-danger validation-text">
                                                {{ 'angular_validation.field' | translate }} {{ 'integrations.kiosk_mode.code_length' | translate }}
                                                {{ 'angular_validation.cannot_be_greater' | translate }} 16
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="text-right">
                                            <small class="text-muted">
                                                {{ 'integrations.kiosk_mode.code_length_explanation' | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{'integrations.kiosk_mode.datetime_types' | translate}} </label>
                                            <ng-select [items]="datetimeTypes$ | async"
                                                       [multiple]="true"
                                                       [closeOnSelect]="false"
                                                       placeholder="{{ 'integrations.kiosk_mode.datetime_types_placeholder' | translate }}"
                                                       bindLabel="name"
                                                       bindValue="employee_datetime_type_ID"
                                                       class="custom"
                                                       formControlName="datetime_types">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="text-right">
                                            <small class="text-muted">
                                                {{ 'integrations.kiosk_mode.datetime_types_explanation' | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <span> {{ 'integrations.kiosk_mode.your_tenant_name' | translate }} </span>
                                        <span class="font-weight-bold"> {{ loggedUser.workspace.value }} </span>
                                        <div class="text-right mt-2">
                                            <small class="text-muted">
                                                {{ 'integrations.kiosk_mode.explanation' | translate }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form *ngSwitchCase="'signi'" [formGroup]="signiForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.signi.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input
                                            (click)="setCollapse('is_active', signiForm)"
                                            [attr.aria-expanded]="!isCollapsed"
                                            [id]="integration.key + '-is_active'"
                                            aria-controls="collapse"
                                            data-switch="success"
                                            formControlName="is_active"
                                            type="checkbox"
                                        >
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                            </div>

                            <div
                                #collapse="ngbCollapse"
                                [(ngbCollapse)]="!isCollapsed"
                                formGroupName="config"
                            >
                                <div class="row">
                                    <div class="col-12">
                                        <h4>
                                            {{"integrations.settings" | translate}}
                                        </h4>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.signi.access_token" | translate}} </label>
                                            <div>
                                                <input
                                                    formControlName="access_token"
                                                    name="access_token"
                                                    class="form-control"
                                                    type="text"
                                                >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.signi.signing_order" | translate}} </label>
                                            <div>
                                                <ng-select
                                                    formControlName="signing_order"
                                                    name="signing_order"
                                                    class="custom"
                                                    bindLabel="label"
                                                    bindValue="value"
                                                    [items]="signiSelectableItems.signing_order"
                                                >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.signi.proposer" | translate}} </label>
                                            <div>
                                                <ng-select
                                                    formControlName="proposer"
                                                    name="proposer"
                                                    class="custom"
                                                    bindLabel="label"
                                                    bindValue="value"
                                                    [items]="signiSelectableItems.proposer"
                                                >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.signi.owner_sign_type" | translate}} </label>
                                            <div>
                                                <ng-select
                                                    formControlName="owner_sign_type"
                                                    name="owner_sign_type"
                                                    class="custom"
                                                    bindLabel="label"
                                                    bindValue="value"
                                                    [items]="signiSelectableItems.owner_sign_type"
                                                >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="control-label"> {{"integrations.signi.responsible_sign_type" | translate}} </label>
                                            <div>
                                                <ng-select
                                                    formControlName="responsible_sign_type"
                                                    name="responsible_sign_type"
                                                    class="custom"
                                                    bindLabel="label"
                                                    bindValue="value"
                                                    [items]="signiSelectableItems.responsible_sign_type"
                                                >
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </form>

                        <form *ngSwitchCase="'abra'" [formGroup]="abraForm" autocomplete="off">
                            <div class="row mt-4">
                                <div class="col-9">
                                    <label class="control-label"> {{"integrations.abra.is_active" | translate}} </label>
                                </div>
                                <div class="col-3 text-right">
                                    <div>
                                        <input (click)="setCollapse('is_active', abraForm)"
                                               [attr.aria-expanded]="!isCollapsed"
                                               [id]="integration.key + '-is_active'"
                                               aria-controls="collapse" data-switch="success" formControlName="is_active" type="checkbox">
                                        <label [for]="integration.key + '-is_active'">&nbsp;</label>
                                    </div>
                                </div>
                                <div #collapse="ngbCollapse" [(ngbCollapse)]="!isCollapsed" formGroupName="config" class="col-12">
                                    <h4>{{"integrations.settings" | translate}}</h4>
                                    <div class="row">
                                        <div class="col form-group d-block">
                                            <label class="control-label"> {{"integrations.abra.server" | translate}} </label>
                                            <input formControlName="server" name="server" class="form-control" type="text">
                                        </div>
                                        <div class="col form-group d-block">
                                            <label class="control-label"> {{"integrations.abra.port" | translate}} </label>
                                            <input formControlName="port" name="port" class="form-control" type="number">
                                        </div>
                                    </div>
                                        <div class="form-group d-block">
                                            <label class="control-label"> {{"integrations.abra.company" | translate}} </label>
                                            <input formControlName="company" name="company" class="form-control" type="text">
                                        </div>
                                    <div class="row">
                                        <div class="col form-group d-block">
                                            <label class="control-label"> {{"integrations.abra.api_user" | translate}} </label>
                                            <input formControlName="api_user" name="api_user" class="form-control" type="text">
                                        </div>
                                        <div class="col form-group d-block">
                                            <label class="control-label"> {{"integrations.abra.api_password" | translate}} </label>
                                            <input formControlName="api_password" name="api_password" class="form-control" type="text">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <div class="row justify-content-end">
                            <div class="col-6 text-right">
                                <button (click)="onSubmit()"
                                        submitButton
                                        class="btn btn-primary">
                                    <span>{{ 'integrations.modal_save' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingTemplate>
    <div class="row mt-4">
        <div *ngFor="let num of Array(2)" class="col-12">
            <div class="w-100 pb-3">
                <div>
                    <ngx-skeleton-loader
                        [theme]="{ width: '200px', height: '20px' }"
                    ></ngx-skeleton-loader>
                </div>
                <div>
                    <ngx-skeleton-loader
                        [theme]="{ width: '50px', height: '20px' }"
                    ></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
</ng-template>
