
<ng-container *ngIf="subscriptionInfo$ | async as subscription">
    <div class="row">
        <div class="col-6">
            <label class="control-label ai-label">{{ 'chat.use_ai_helper' | translate }} <i class="mdi mdi-creation mr-1"></i></label>
        </div>
        <div class="col-6 text-right">
            <input
                data-switch="success"
                id="helperActive"
                name="helperActive"
                type="checkbox"
                [checked]="helperActive"
                (change)="helperActive = !helperActive"
            >
            <label class="mb-0" for="helperActive">&nbsp;</label>
        </div>
    </div>
    <div [(ngbCollapse)]="!helperActive">
        <fieldset class="fieldset-bordered">
            <legend>{{ 'chat.ai_helper' | translate }}</legend>
            <form autocomplete="off" [formGroup]="aiForm" (ngSubmit)="onSubmit(subscription)">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label *ngIf="scope !== 'RECRUITMENT' && scope !== 'RECRUITMENT_EVALUATION'" class="control-label">  {{'chat.send_message' | translate }} </label>
                            <span *ngIf="scope !== 'RECRUITMENT' && scope !== 'RECRUITMENT_EVALUATION' && scope !== 'RECRUITMENT_ADVERTISEMENT'" class="mdi mdi-help-circle help-icon mdi-18px"
                                  [ngbTooltip]="tooltip"
                                  [tooltipClass]="'ai-help-tooltip'"
                                  [placement]="'bottom-end'"></span>
                            <ng-template #tooltip>
                                <div class="mb-2"> {{('chat.help_text_title_' + scope) | translate }}</div>
                                <div class="mb-1"> {{ 'chat.help_text_examples' | translate }}</div>
                                <ul>
                                    <li *ngFor="let feature of ('chat.help_text_list_' + scope | translate)">{{ feature }}</li>
                                </ul>
                            </ng-template>

                            <textarea *ngIf="scope !== 'RECRUITMENT' && scope !== 'RECRUITMENT_EVALUATION'" class="form-control" formControlName="message" name="message" type="text"></textarea>

                            <div *ngIf="submitted && formGroup?.get('name')?.errors?.required">
                                <p class="text-danger validation-text" >
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'chat.name' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                            <div *ngIf="submitted && aiForm.get('message')?.errors?.required">
                                <p class="text-danger validation-text">
                                    <span>{{ 'angular_validation.field' | translate }} </span>
                                    <span>{{ 'chat.message' | translate }} </span>
                                    <span>{{ 'angular_validation.required' | translate }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 align-items-center">
                    <div [ngClass]="{'text-danger': subscription.ai.available_credits === 0}" class="col-auto">
                        <span> {{ 'chat.remaining_credits' | translate }}: {{subscription.ai.available_credits}} / {{subscription.ai.total_credits}} </span>
                        <span class="mdi mdi-help-circle mdi-18px ml-1"
                              [ngbTooltip]="('chat.credits_info_part_1' | translate) + (subscription.ai.anchor_date | date: 'dd.MM.yyyy') + '. ' + ('chat.credits_info_part_2' | translate)"
                              [placement]="'bottom-start'"></span>
                    </div>
                    <div class="col text-right">
                        <button submitButton style="min-width: 100% !important;" class="btn btn-sm btn--ai" type="submit">
                            <i class="mdi mdi-creation mr-1"></i>{{buttonText | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </fieldset>
    </div>
</ng-container>
