import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private sentryErrorHandler = Sentry.createErrorHandler({
        showDialog: false, // Nechceme zobrazovat Sentry UI dialog
    });

    constructor() {}

    handleError(error: any): void {
        if (error && error.name === 'ChunkLoadError') {
            console.error('ChunkLoadError detected. Reloading page...');
            window.location.reload(); // Malé zpoždění pro logování
            return;
        }

        this.sentryErrorHandler.handleError(error);
    }
}
